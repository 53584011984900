export const OLD_ROUTES = {
  APPLY_FORM: {
    COMPLETE: '/SignUpComplete.html',
    CONTACT_DETAILS: '/contact-details.html',
    PAYMENT: '/SignUpPayment.html',
    PICK_DOCUMENT_TYPE: '/pick-document-type.html',
    SELFIE_VIDEO: '/selfie-video.html',
    VERIFICATION_COMPLETE: '/verification-complete.html',
    VERIFY_ID: '/verify-id.html',
    VERIFY_ID_BACK: '/SignUpPhotoBack.html',
    VERIFY_INFORMATION: '/SignUpInformation.html'
  },
  TOKENIZED_IDENTITY: '/TokenizedIdentity.html',
  ABOUT_US: '/AboutUs.html',
  BIOMETRIC_EVENT_CHECKIN: '/BiometricEventCheckin.html',
  HUMANITARIAN: '/Humanitarian.html',
  BANKING_AND_FINTECH: '/BankingAndFintech.html',
  CONTACT_US: '/ContactUs.html',
  SCHEDULE_DEMO: '/ScheduleDemo.html',
  UNSUBSCRIBE: '/Unsubscribe.html',
  SHARED_CONTACT_US: '/SharedContactUs.html',
  SHARED_MINI_CONTACT_US: '/SharedMiniContactUs.html',
  CONTACT_US_THANK_YOU: '/ContactUsThankYou.html',
  SHARED_CONTACT_US_THANK_YOU: '/SharedContactUsThankYou.html',
  LOGIN: '/login.html',
  RESET_PASSWORD: '/reset-password.html',
  SELF_TEST: '/SelfTest.html',
  WALLET_INSIGHT_FORM: '/EvaluateLeadAccelerator.html',
  WALLET_INSIGHT_PROFILE: '/ViewLeadAccelerator.html',
  WALLET_INSIGHT_REGISTRATION: '/RegisterLeadAccelerator.html',
  CCPA_FORM: '/CCPAForm.html',
  SHARED_CCPA_FORM: '/SharedCCPAForm.html',
  CCPA_THANK_YOU: '/CCPAThankYou.html',
  PIXELPIN_THANK_YOU: '/PixelPinThankYou.html',
  SHARED_CCPA_THANK_YOU: '/SharedCCPAThankYou.html',
  PRIVACY_POLICY: '/PrivacyPolicy.html',
  TERMS_AND_CONDITIONS: '/TermsAndConditions.html',
  // SHARED_TERMS_AND_CONDITIONS: '/SharedTermsAndConditions.html',
  ANNOUCEMENTS: '/DTCannoucement.html',
  TECHNOLOGY: '/Technology.html',
  MISSION: '/OurMission.html',
  TEAM: '/TeamMembers.html',
  CAREERS: '/Careers.html',
  NEWS: '/News.html',
  IDENTITY_TOOLS: '/FullSuiteOfIdentityTools.html',
  SECURED_EMAIL: '/SecuredEmail.html',
  KYC: '/kyc.html',
  REAL_ESTATE: '/RealEstate.html',
  WHITEPAPER: '/Whitepaper.html',
  PROTECT: '/Protect.html',
  PRIVTECH: '/Privtech.html',
  CAPTURE: '/Capture.html',
  IDENTIFY: '/Identify.html',
  VERIFY: '/Verify.html',
  DETECT: '/Detect.html',
  PIXELPINCCPA: '/PixelPinCCPA.html',
  DATA_BREACHES: '/DataBreaches.html',
  COOKIES: '/Cookies.html',
  CRYPTO: '/Cryptocurrency.html',
  B_BTA: '/BlogBTA.html',
  AGE_ESTIMATION: '/AgeEstimation.html',
  STABLE_IT2: '/StableIT2.html',
  WIRE_FRAUD_PREVENTION: '/WireFraudPrevention.html',
  B_PANDEMIC: '/BlogBeyondPandemic.html',
  B_PROGRESS: '/BlogProgress.html',
  B_BREACHES: '/BlogDataBreaches.html',
  B_CRYPTO: '/BlogCrypto.html',
  B_KBA: '/BlogKBA.html',
  BANK_CASE_STUDY: '/BankCaseStudy.html',
  DIGITAL_IDENTITY: '/DigitalIdentity.html',
  AGE_VERIFICATION: '/AgeVerification.html',
  SUPPORTED_COUNTRIES: '/SupportedCountries.html',
  INVESTORS: 'https://investors.truststamp.ai/',
  WIRE_WHITEPAPER: 'https://resources.truststamp.ai/trust-stamps-wire-fraud-whitepaper',
  B_RESOURCES: 'https://resources.truststamp.ai/',
  INDUSTRIES: 'https://industries.truststamp.ai/',
  STABLE_IT2_WHITEPAPER: 'https://resources.truststamp.ai/trust-stamps-ai-powered-stable-it2-technology-whitepaper',
  GEOLOCATION: 'https://trustedcheck-in.truststamp.ai',
  PARTNERS: 'https://partners.truststamp.ai',
  BIOMETRIC_MFA: 'https://partners.truststamp.ai/biometric-mfa',
  GENERAL_COUNSEL: 'https://euro.lawyer',
  BLOGS: 'https://blogs.truststamp.ai/',
  TRUSTED_MESSAGE: 'https://trustedmessage.truststamp.ai/en/trusted-message',
  DISCLOSURE: 'https://disclosures.truststamp.ai',
  ONLINE_GAMING: 'https://industries.truststamp.ai/gambling-kyc-online-gaming-kyc'
};

export const ROUTES = {
  APPLY_FORM: {
    COMPLETE: '/signup-complete.html',
    CONTACT_DETAILS: '/contact-details.html',
    PAYMENT: '/signup-payment.html',
    PICK_DOCUMENT_TYPE: '/pick-document-type.html',
    SELFIE_VIDEO: '/selfie-video.html',
    VERIFICATION_COMPLETE: '/verification-complete.html',
    VERIFY_ID: '/verify-id.html',
    VERIFY_ID_BACK: '/signup-photo-back.html',
    VERIFY_INFORMATION: '/signup-information.html'
  },
  UNSUBSCRIBE: '/email-unsubscribe.html',
  PRIVTECH: '/privacy-technology.html',
  CAREERS: '/jobs.html',
  CAPTURE: '/liveness-detection.html',
  TECHNOLOGY: '/our-technology.html',
  NEWS: '/news-updates.html',
  LOGIN: '/account-login.html',
  HUMANITARIAN: '/humanitarian-initiatives.html',
  WHITEPAPER: '/whitepaper-overview.html',
  COOKIES: '/cookies-policy.html',
  CRYPTO: '/crypto.html',
  MONEY_2020: '/money2020.HTML',
  ABOUT_US: '/about-us.html',
  KYC: '/kyc.html',
  TOKENIZED_IDENTITY: '/tokenized-identity.html',
  BIOMETRIC_EVENT_CHECKIN: '/biometric-event-checkin.html',
  BANKING_AND_FINTECH: '/banking-and-fintech.html',
  SHARED_CONTACT_US: '/shared-contact-us.html',
  CONTACT_US: '/contact-us.html',
  SCHEDULE_DEMO: '/schedule-demo.html',
  SHARED_MINI_CONTACT_US: '/shared-mini-contact-us.html',
  RESET_PASSWORD: '/reset-password.html',
  CCPA_FORM: '/ccpa-form.html',
  SELF_TEST: '/self-test.html',
  CONTACT_US_THANK_YOU: '/contact-us-thank-you.html',
  SHARED_CONTACT_US_THANK_YOU: '/shared-contact-us-thank-you.html',
  WALLET_INSIGHT_FORM: '/evaluate-lead-accelerator.html',
  WALLET_INSIGHT_PROFILE: '/view-lead-accelerator.html',
  WALLET_INSIGHT_REGISTRATION: '/register-lead-accelerator.html',
  SHARED_CCPA_FORM: '/shared-ccpa-form.html',
  CCPA_THANK_YOU: '/ccpa-thank-you.html',
  PIXELPIN_THANK_YOU: '/pixelpin-thank-you.html',
  SHARED_CCPA_THANK_YOU: '/shared-ccpa-thank-you.html',
  TERMS_AND_CONDITIONS: '/terms-and-conditions.html',
  SHARED_TERMS_AND_CONDITIONS: '/shared-terms-and-conditions.html',
  ANNOUCEMENTS: '/dtc-announcement.html',
  PRIVACY_POLICY: '/privacy-policy.html',
  MISSION: '/our-mission.html',
  TEAM: '/team-members.html',
  IDENTITY_TOOLS: '/full-suite-of-identity-tools.html',
  REAL_ESTATE: '/real-estate.html',
  SECURED_EMAIL: '/secured-email.html',
  PROTECT: '/data-protection-privacy.html',
  IDENTIFY: '/identity-document-verification.html',
  INVESTMENT_CALCULATOR: '/roi-calculator.html',
  VERIFY: '/digital-identity-verification.html',
  DETECT: '/duplicate-identity-detection.html',
  PIXELPINCCPA: '/pixelpin-ccpa.html',
  DATA_BREACHES: '/data-breaches.html',
  B_BTA: '/blog-bta.html',
  AGE_ESTIMATION: '/age-estimation.html',
  STABLE_IT2: '/stable-it2.html',
  BIOMETRIC_MFA: '/biometric-mfa.html',
  WIRE_FRAUD_PREVENTION: '/wire-fraud-prevention.html',
  B_PANDEMIC: '/blog-beyond-pandemic.html',
  B_PROGRESS: '/blog-progress.html',
  B_BREACHES: '/blog-data-breaches.html',
  B_CRYPTO: '/blog-crypto.html',
  B_KBA: '/blog-kba.html',
  BANK_CASE_STUDY: '/bank-case-study.html',
  DIGITAL_IDENTITY: '/digital-identity.html',
  AGE_VERIFICATION: '/age-verification.html',
  SUPPORTED_COUNTRIES: '/supported-documents.html',
  TRAVEL_HOSPITALITY: '/travel-hospitality.html',
  DEDUPLICATION_CHECKS: '/deduplication-greylist-checks.html',
  // external links
  INVESTORS: 'https://investors.truststamp.ai/',
  INDUSTRIES: 'https://industries.truststamp.ai/',
  STABLE_IT2_WHITEPAPER: 'https://resources.truststamp.ai/trust-stamps-ai-powered-stable-it2-technology-whitepaper',
  WIRE_WHITEPAPER: 'https://resources.truststamp.ai/trust-stamps-wire-fraud-whitepaper',
  GEOLOCATION: 'https://trustedcheck-in.truststamp.ai',
  B_RESOURCES: 'https://resources.truststamp.ai/',
  PARTNERS: 'https://partners.truststamp.ai',
  GENERAL_COUNSEL: 'https://euro.lawyer',
  BLOGS: 'https://blogs.truststamp.ai/',
  TRUSTED_MESSAGE: 'https://trustedmessage.truststamp.ai/en/trusted-message',
  DISCLOSURE: 'https://disclosures.truststamp.ai',
  ONLINE_GAMING: 'https://industries.truststamp.ai/gambling-kyc-online-gaming-kyc'
};


export const ROLES = {
  SCORE_USER: 'scoreUser',
  BUSINESS_USER: 'business',
  ORGANIZATION_AGENT: 'organizationAgent',
};

export const SELFIE_RETAKE_REASONS = {
  FACE_NOT_FOUND: 'FACE_NOT_FOUND',
  SCORE_FAILURE: 'SCORE_FAILURE',
};

export const BUSINESS_LOGIN_STEPS = {
  CREDENTIALS: 'CREDENTIALS',
  SELFIE: 'SELFIE',
};

export const DRIVING_LICENSE_STEPS = {
  TUTORIAL: Symbol('TUTORIAL'),
  FRONT_CAPTURE: Symbol('FRONT_CAPTURE'),
  DL_BACK_CAPTURE: Symbol('DL_BACK_CAPTURE'),
  FLIP_DL: Symbol('FLIP_DL'),
};

export const DOCUMENT_TYPES = [
  {
    label: 'Driving license',
    value: 'driving_license',
  },
  {
    label: 'Passport',
    value: 'passport',
  },
];

export const BUSINESS_SERVICE = {
  id: 'business',
  name: 'NRDS Account',
  isUsedDuringAuth: true,
  icon: 'truststamp',
  isBusinessOnly: true,
};

export const ORGANIZATION_SERVICE = {
  id: 'business',
  name: 'Admin account',
  isUsedDuringAuth: true,
  icon: 'truststamp',
  isBusinessOnly: true,
};

export const SERVICES = [
  {
    id: 'facebook',
    name: 'Facebook',
    isUsedDuringAuth: true,
    icon: 'facebook',
  }, {
    id: 'linkedin',
    name: 'LinkedIn',
    isUsedDuringAuth: true,
    icon: 'linkedin',
  }, {
    id: 'twitter',
    name: 'Twitter',
    isUsedDuringAuth: true,
    icon: 'twitter',
  }, {
    id: 'instagram',
    name: 'Instagram',
    isUsedDuringAuth: true,
    icon: 'instagram',
  }, {
    id: 'google',
    name: 'Google',
    isUsedDuringAuth: true,
    icon: 'google',
    useWebapp: true, // Redirect from realtor app to web browser
  }, {
    id: 'reddit',
    name: 'Reddit',
    isUsedDuringAuth: false,
    icon: 'reddit',
  },
];

export const PIN_CODE_LENGTH = 4;

export const WOOPRA_EVENTS_MAP = {
  'WEBAPP_REGISTRATION_STARTED_REALTOR': {
    name: 'is.Realtor',
  },
  'WEBAPP_REGISTRATION_STARTED_REALTOR_CLIENT': {
    name: 'is.RealtorClient',
  },
  'WEBAPP_REGISTRATION_STARTED': {
    name: 'is.TrustStampOwner',
  },
  'WEBAPP_REGISTRATION_SOCIAL_NETWORK_STEP_DONE': {
    name: 'signup.socialadded',
  },
  'WEBAPP_REGISTRATION_PHONE_STEP_DONE': {
    name: 'signup.cellphone',
  },
  'WEBAPP_REGISTRATION_PHONE_VERIFICATION_STEP_DONE': {
    name: 'signup.cellverified',
  },
  'WEBAPP_REGISTRATION_BASIC_INFO_STEP_DONE': {
    name: 'signup.basicinfo',
  },
  'WEBAPP_REGISTRATION_POL_VIDEO_STEP_DONE': {
    name: 'signup.polvideo',
  },
  'WEBAPP_REGISTRATION_SELFIE_STEP_DONE': {
    name: 'signup.selfie',
  },
  'WEBAPP_REGISTRATION_DRIVERS_LICENCE_STEP_DONE': {
    name: 'signup.driverslicence',
  },
  'WEBAPP_REGISTRATION_EMAIL_CONFIRMED': {
    name: 'signup.emailconfirmed',
  },
  'WEBAPP_PROFILE_PUBLISHED': {
    name: 'webapp.Published',
  },
  'WEBAPP_PROFILE_UNPUBLISHED': {
    name: 'webapp.Unpublished',
  },
  'WEBAPP_SELFIE_LOGIN_SUCCESS': {
    name: 'webapp.SelfieloginSuccess',
  },
  'WEBAPP_SELFIE_LOGIN_FAILURE': {
    name: 'webapp.SelfieloginFailure',
  },
  'WEBAPP_PIN_LOGIN_SUCCESS': {
    name: 'webapp.PINloginSuccess',
  },
  'WEBAPP_PIN_LOGIN_FAILURE': {
    name: 'webapp.PINloginFailure',
  },
  'WEBAPP_VERIFICATION_SENT': {
    name: 'webapp.asktoverify',
  },
  'WEBAPP_INVITATION_SENT': {
    name: 'webapp.invite',
  },
  'WEBAPP_PREPAID_PROFILE_SHARE_SENT': {
    name: 'webapp.SharePrepaid',
  },
  'WEBAPP_PROFILE_SHARE_SENT': {
    name: 'webapp.ShareFree',
  },
  'WEBAPP_CREDITS_ADDED': {
    name: 'webapp.AddedCredits',
  },
  'REALTOR_ACCESS_PROFILE': {
    name: 'app.RealtorAccess',
  },
};

export const AGE_OF_ELIGIBILITY = 18;
export const OLDEST_PERSON_BIRTHDATE = '1900-08-04';
export const FAILED_LOGIN_ATTEMPTS_BEFORE_NOTIFICATION = 2;

export const KEYCODES = {
  ENTER: 13,
  ESCAPE: 27,
};

export const STATUSES = {
  amber: 'amber',
  default: 'default',
  green: 'green',
  red: 'red',
};

export const BRANDINGS = {
  DEFAULT: 'default',
  REALTOR: 'realtor',
  CORPORATE_LANDING: 'corporate_landing',
  ORGANIZATION_SUPERVISOR: 'organization',
  AI: 'ai',
};

const DEFAULT_LOGO_PROPS = {
  content: 'TrustStamp',
  toSignedIn: '/Profile.html',
  to: '/',
};

export const LOGO_VARIANTS = {
  'default': {
    ...DEFAULT_LOGO_PROPS,
    to: undefined,
  },
  'realtor': {
    ...DEFAULT_LOGO_PROPS,
  },
  'corporate_landing': {
    ...DEFAULT_LOGO_PROPS,
    toSignedIn: '/',
  },
  'ai': {
    ...DEFAULT_LOGO_PROPS,
    toSignedIn: '/',
  },
};

export const YOUTUBE_ASPECT_RATIO = 0.710976;

export const CURRENCY_SYMBOLS = {
  USD: '$',
};

export const HUBSPOT = {
  PORTAL_ID: '21849861',
  FORM_ID: 'e2578161-8349-40e7-be02-4f9956781f04',
  UNSUBSCRIBE_FORM_ID: '85971639-13e9-464e-aeca-ee2e2d83b82e',
};
