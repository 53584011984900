import { ROUTES } from 'constants';

/* eslint-disable max-len */
export const PRODUCTS = [
  // {
  //   id: 1,
  //   title: 'Cyber Essentials Certification',
  //   text: 'TrustStamp ensures robust cybersecurity through our Cyber Essentials Certification, setting the standard for resilient online protection.',
  //   slug: 'cyberessentials',
  //   className: 'cyberessentials',
  // },
  {
    id: 2,
    title: 'Cyber Essentials Plus Certification',
    text: 'Raising the bar with enhanced security, TrustStamp\'s Cyber Essentials Plus Certification underscores our commitment to safeguarding your digital assets. Your trust is our ',
    slug: 'cyberessentials-plus',
    className: 'cyberessentials',
  },
  {
    id: 3,
    title: 'D-seal',
    text: 'The D-Seal signifies our commitment to top-tier data security, offering a trusted standard for safeguarding your information.',
    button: 'More About D-seal',
    href: 'https://d-seal.eu/d-sealed-companies/trust-stamp-denmark-aps/',
    slug: 'D-seal',
    className: 'D-seal',
  },
  {
    id: 4,
    title: 'SOC 2',
    text: 'Trust Stamp has achieved SOC 2 Type II Security, Availability & Confidentiality Report compliance.',
    button: 'Read More',
    href: 'https://blogs.truststamp.ai/trust-stamp-achieves-soc-2-compliance',
    slug: 'SOC_2',
    className: 'SOC_2',
  },
];
