/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { ROUTES } from 'constants';
import BaseComponent from 'components/BaseComponent';
import CalculatorForm from './CalculatorForm';

export default class RioCalculator extends BaseComponent {
  className = 'ts-RioCalculator';

  render() {
    return (
      <section className={ this.rootcn`` }>
        <section>
          <div className="ts-container ts-container__column">
            <h3>ROI Calculator</h3>
            <p>1.What is your industry?</p>
            <ul className={ this.cn`__radio` }>
              <li>
                <input
                  type="radio"
                  id="FinancialServices"
                  name="industry"
                  value="FinancialServices"
                />
                <label
                  className={ this.cn`__radio--label` }
                  htmlFor="FinancialServices"
                >
                  Financial Services
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="iGamingGambling"
                  name="industry"
                  value="iGamingGambling"
                />
                <label
                  className={ this.cn`__radio--label` }
                  htmlFor="iGamingGambling"
                >
                  iGaming/Gambling
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="TravelHospitality"
                  name="industry"
                  value="TravelHospitality"
                />
                <label
                  className={ this.cn`__radio--label` }
                  htmlFor="TravelHospitality"
                >
                  Travel/Hospitality
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="Telecommunications"
                  name="industry"
                  value="Telecommunications"
                />
                <label
                  className={ this.cn`__radio--label` }
                  htmlFor="Telecommunications"
                >
                  Telecommunications
                </label>
              </li>

              <li>
                <input type="radio" id="Dating" name="industry" value="Dating" />
                <label className={ this.cn`__radio--label` } htmlFor="Dating">
                  Dating
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="Marketplaces"
                  name="industry"
                  value="Marketplaces"
                />
                <label className={ this.cn`__radio--label` } htmlFor="Marketplaces">
                  Marketplaces
                </label>
              </li>

              <li>
                <input
                  type="radio"
                  id="Healthcare"
                  name="industry"
                  value="Healthcare"
                />
                <label className={ this.cn`__radio--label` } htmlFor="Healthcare">
                  Healthcare
                </label>
              </li>

              <li>
                <input type="radio" id="Crypto" name="industry" value="Crypto" />
                <label className={ this.cn`__radio--label` } htmlFor="Crypto">
                  Crypto
                </label>
              </li>

              <li>
                <input type="radio" id="Other" name="industry" value="Other" />
                <label className={ this.cn`__radio--label` } htmlFor="Other">
                  Other
                </label>
              </li>
            </ul>
          </div>
          <hr className={ this.cn`__divider` } />
        </section>
        <div className="ts-container">
          <div className={ this.cn`__column` }>
            <CalculatorForm />
          </div>
          <div className={ this.cn`__column` }>
            <div className={ this.cn`__left-content` }>
              <h4>How Trust Stamp delivers ROI:</h4>
              <hr className={ this.cn`__divider` } />
              <ul>
                <li>
                  <h3>$61,500</h3>
                  <p>
                    Save up to $61,500 per month on identity verification costs.
                  </p>
                </li>

                <li>
                  <h3>$87,500,000</h3>
                  <p>
                    Improved conversion rates could add up to $87.5 million in
                    additional monthly revenue.
                  </p>
                </li>

                <li>
                  <h3>5.4%</h3>
                  <p>Lower your fraud rate by 5.4% or more.</p>
                </li>
                <hr className={ this.cn`__divider` } />
                <li>
                  <Link to={ ROUTES.CONTACT_US } className="ts-button--filled">
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
