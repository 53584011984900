/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { asset } from 'sf/helpers';
import { ROUTES } from 'constants';
// import Banner from "components/Banner";
import RioCalculator from './components/RioCalculator';
import Main from './components/Main';
import Resources from 'components/Resources';
import help from 'models/help';

InvestmentCalculator;
export default class InvestmentCalculator extends BasePage {
  className = 'ts-InvestmentCalculator';
  static title = 'RIO Calculator';
  static description =
    'Trust Stamp Verify provides a low-friction way to enhance risk management and data protection measures, while protecting sensitive information from potential identity thieves.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { /* banner */ }
        { /* <Banner theme="phone-photo" size="small">
          <h1>ROI Calculator</h1>
        </Banner> */ }

        { /* what we do section */ }
        <Main />

        <RioCalculator />

        { /* resources section */ }
        <Resources>
          <article>
            <h6>RESOURCES</h6>
            <h2>
              Trust Stamp's Biometric Authentication with Stable IT2 - A Path
              from Passwords to FIDO, Passkeys and Beyond
            </h2>
            <p>
              Stable IT2, developed by Trust Stamp, is a breakthrough in facial
              recognition technology designed for identity authentication,
              secure system access, and account protection. The Stable IT2
              secures a renewable cryptographic secret using a facial image.
              <strong>
                Stable IT2 revolutionizes data security in biometric systems by
                eliminating the need to store biometric data.
              </strong>
            </p>
            <Link to={ ROUTES.WHITEPAPER } className="ts-button--primary">
              Get the Whitepaper
            </Link>
          </article>
          <aside>
            <img
              src={ asset`/ai/future-proofing.svg` }
              alt="Privacy-Protecting Power of the Irreversibly Transformed Identity Token"
              width="400"
            />
          </aside>
        </Resources>
      </div>
    );
  }
}
