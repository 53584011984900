/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import Benefits from 'components/Benefits';
import Cards from 'components/Cards';
import { asset } from 'sf/helpers';
import { TITLE2, CONTENT, CONTENT2, CONTENT3 } from '../../constants';

export default class ProtectContent extends BaseComponent {
  className = 'ts-AgeVerificationContent';

  render() {
    return (
      <div className={ this.rootcn() }>
        <section>
          <div className="ts-container">
            <div className={ this.cn`__column __column--left` }>
              <h6>Age Estimation by truststamp</h6>
              <h2>Age Estimation & Liveness Detection</h2>
              <p>
                At Trust Stamp, we've revolutionized age estimation with a
                privacy-focused strategy. Our advanced Biometric Age Estimation
                tools empower users to prove their eligibility for your
                products, services, or content seamlessly by taking a secure
                selfie without sharing sensitive personal information or
                biometric templates, protecting the privacy of your users and
                your platform against data breaches. Age estimation is performed
                by asking a user to take a selfie. Trust Stamp’s algorithms
                analyze the photo to estimate an individual's age by examining
                the facial characteristics of an individual. We've integrated
                advanced liveness detection technology into our age estimation
                process. This ensures that users are genuinely present during
                verification, safeguarding against "replay attacks" where the
                user might attempt to bypass the system using photos or displays
                of another individual's face. By ensuring the real-time presence
                of users, Trust Stamp's liveness detection elevates the
                reliability of age estimations, offering an additional layer of
                security that upholds the authenticity of every transaction.
              </p>
            </div>
            <div className={ this.cn`__column __column--right` }>
              <img
                src={ asset`ai/it2.png` }
                alt="Privacy and data protection"
                width="217px"
              />
            </div>
          </div>
        </section>
        <Benefits sectionTitle={ TITLE2 } content={ CONTENT } />
        <section>
          <div className="ts-container">
            <h2>Meet Legal Requirements</h2>
            Failure to meet regulatory requirements and providing age-restricted
            goods, services, or content to underage users can result in fines,
            damaged brand reputation, and, in some cases, business license
            revocations. <br />
            <br />
            <strong>
              { ' ' }
              Protect your business by making sure your customers are
              age-appropriate in a snap
            </strong>
          </div>
          <Cards content={ CONTENT2 } columnCount={ 2 } />
        </section>
        <section>
          <div className="ts-container">
            <h2>Enhance the User Experience</h2>
            Ensure your users are of the correct age without sacrificing the
            user experience. Increase conversion rates with a simple and
            convenient process that instantly checks if an individual is
            over/under your specified age.
            <br />
            <br />
            <strong> Unlock a Substantial Transformation</strong>
          </div>
          <Cards content={ CONTENT3 } columnCount={ 3 } />
        </section>
      </div>
    );
  }
}
