/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';

export default class Work extends BaseComponent {
  className = 'ts-Work';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className={ this.cn`__container ts-container` }>
          <div className={ this.cn`__content` }>
            <div className={ this.cn`__left-content` }>
              <h6>Identity Verification ROI Calculator</h6>
              <h2>
                Discover the Real Impact of Privacy-First Identity Verification
              </h2>
              <p>
                Are you looking to protect your business from fraud, strengthen
                customer trust, and ensure compliance—all while boosting your
                bottom line? With the right identity verification partner, you
                don’t have to compromise.
                <br />
                <br /> Just answer the following questions to discover how Trust
                Stamp’s IDV solutions can drive growth and measurable returns
                for your business.{ ' ' }
              </p>
            </div>
            <div className={ this.cn`__right-content` }>
              <img
                src={ asset`ai/rio-calculator.svg` }
                alt="Trust Stamp - What we do"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
