import React, { Component } from 'react';

export default class En extends Component {
  render() {
    return (
      <div>
        <h1>
          <u>ALIMENTÉ PAR TRUST STAMP CONDITIONS GÉNÉRALES</u>
        </h1>

        <p>
          <b>Qui est Trust Stamp ?</b>
        </p>
        <p>
          T Stamp Inc. d/b/a Trust Stamp (ci-après " Trust Stamp ", " nous " ou
          " notre ") est une société d'intelligence artificielle dont l'objectif
          est de fournir une sécurité accrue grâce à l'authentification et à la
          vérification biométriques de l'identité. "Powered by Trust Stamp" est
          un service que Trust Stamp fournit à ses clients (chacun étant une
          "Entité de vérification" et collectivement, les "Entités de
          vérification") qui permet à l'Entité de vérification de vérifier de
          manière probabiliste l'identité de son client (un "Utilisateur final")
          par le biais des processus d'authentification sécurisés de Trust
          Stamp.{ ' ' }
        </p>

        <p>
          <b>Comment cela fonctionne ?</b>
        </p>
        <p>
          "Powered by Trust Stamp" authentifie l'identité d'un utilisateur final
          en comparant des données biométriques dérivées d'une image ou d'une
          vidéo de l'utilisateur final à des données dérivées d'une ou de
          plusieurs images connues de l'utilisateur final, d'un ou de plusieurs
          documents d'identité fournis par l'utilisateur final, ou des deux à la
          fois. Ce processus d'authentification biométrique protège à la fois
          l'utilisateur final et l'entité de vérification contre l'accès non
          autorisé au compte et l'usurpation d'identité. Lorsque vous accédez à
          "Powered by Trust Stamp" pour vérifier votre identité auprès d'une
          entité de vérification, vous êtes un "utilisateur final".
        </p>

        <p>
          <b>
            Comment utilisez-vous les données et informations biométriques ?
          </b>
        </p>
        <p>
          La comparaison des mesures biométriques sert de mécanisme pour
          vérifier l'identité d'un utilisateur final et confirmer que le(s)
          document(s) d'identité présenté(s) par un utilisateur final
          appartient(ent) à cet utilisateur final. Nous recueillons et traitons
          vos mesures biométriques brutes à cette fin. Nous traitons vos mesures
          biométriques brutes, qu'elles proviennent d'une image ou d'un document
          en direct, par le biais d'un processus propriétaire pour convertir les
          mesures brutes en un jeton d'identité transformé de manière
          irréversible (IT2)™. L'IT2 est un dérivé unique et tokenisé de vos
          mesures biométriques brutes, mais ne peut pas être rétroconçu pour
          recréer vos mesures brutes. Dans les présentes conditions, les
          "données biométriques" comprennent vos mesures biométriques brutes,
          mais ne comprennent pas l'IT2. Bien que le processus utilisé pour
          créer l'IT2 soit irréversible, l'IT2 conserve des propriétés qui
          peuvent être utilisées pour vérifier votre identité sur une base
          probabiliste. En d'autres termes, nous pouvons comparer une IT2
          générée actuellement à une IT2 générée à partir de vos données
          biométriques dans le passé ou à l'avenir pour vérifier votre identité.
          Nous utilisons ce processus exclusif de comparaison probabiliste pour
          vérifier votre identité pour une entité de vérification.
        </p>

        <p>
          <u>
            <b>
              Les petits caractères (
              <i>très important ; ne passez pas à côté maintenant</i>)
            </b>
          </u>
        </p>
        <p>
          <b>
            Sachez que les présentes conditions comprennent, entre autres, une
            clause d'arbitrage obligatoire qui vous oblige à vous soumettre à un
            arbitrage obligatoire et définitif sur une base individuelle pour
            résoudre les litiges, plutôt qu'à un procès avec jury ou à une
            action collective.
          </b>
        </p>
        <ol>
          <li>
            <p>
              <u>Acceptation ; Politique de confidentialité.</u> Vous devez
              avoir dix-huit (18) ans ou plus (ou l'âge de la majorité légale
              dans votre juridiction de résidence, si elle est plus élevée) pour
              utiliser le timbre Powered by Trust (désigné ci-dessous comme les
              "Services") afin de vérifier votre identité ou d'authentifier
              votre (vos) document(s) d'identité. En accédant et/ou en
              utilisant, ou en continuant d'accéder ou d'utiliser, les Services
              de quelque manière que ce soit, vous déclarez que vous (a) avez au
              moins dix-huit (18) ans ou l'âge minimum requis pour conclure un
              contrat légal contraignant dans votre juridiction de résidence, et
              que vous avez le droit, l'autorité et la capacité de conclure ces
              Conditions ; (b) vous acceptez ces Conditions, qui intègrent
              également par référence la Politique de confidentialité de Trust
              Stamp, qui se trouve ici https://truststamp.ai/PrivacyPolicy. html
              (la " politique de confidentialité "), chacune pouvant être mise à
              jour de temps à autre sans préavis, tel qu'indiqué dans chaque
              document ; (c) si des changements importants ont été apportés aux
              présentes conditions depuis que vous avez accédé aux services ou
              que vous les avez utilisés pour la dernière fois, vous
              reconnaissez et acceptez que votre accès continu aux services ou
              leur utilisation constitue votre acceptation des conditions
              modifiées ; et (d) vous consentez à recevoir des communications de
              notre part par voie électronique et acceptez que ces
              communications, avis et/ou affichages électroniques satisfassent à
              toute exigence juridique voulant que ces communications soient
              faites par écrit.
            </p>
          </li>

          <li>
            <p>
              <u>Accès aux services.</u>
              Sous réserve que vous respectiez les présentes conditions, nous
              vous accordons une licence limitée, non exclusive, non
              sous-licenciable, révocable et non transférable pour accéder aux
              services et les utiliser. L'utilisation des services est
              subordonnée à l'exactitude, à l'actualité et à l'exhaustivité des
              informations que vous fournissez. Vous utilisez les services à vos
              propres risques et vous reconnaissez que le processus utilisé est
              probabiliste et ne peut fournir une vérification absolue de votre
              identité. Tous les droits qui ne sont pas expressément accordés
              aux présentes sont réservés par Trust Stamp. Il vous appartient de
              prendre toutes les dispositions nécessaires pour accéder aux
              Services. Les Services comprennent, sans limitation, tous les
              services, applications ou extensions offerts par nous que nous
              vous fournissons directement ou que vous téléchargez ou recevez
              d'une Entité de vérification ou de magasins d'applications tiers,
              sous réserve des conditions de ces magasins ou de leurs
              fournisseurs. Pour utiliser les services, vous devez disposer d'un
              matériel, d'un logiciel et d'un accès à l'internet compatibles.
              Nous ne faisons aucune déclaration ni ne donnons aucune garantie
              concernant les appareils que vous utilisez pour accéder aux
              services ou les utiliser, y compris en ce qui concerne la
              compatibilité de tout appareil avec les services.
            </p>
          </li>

          <li>
            <b>
              <p>
                <u>
                  Consentement pour la collecte, le traitement et l'utilisation
                  des données biométriques.
                </u>
                Lorsque vous utilisez les Services, nous vous demandons de
                fournir une ou plusieurs images de vous-même (c'est-à-dire un
                "selfie") (votre "image de vérification"), un ou plusieurs
                documents d'identité officiels avec photo (votre "document
                d'identification"), ou les deux. En utilisant les services, vous
                consentez à ce que nous collections, traitions, stockions et
                utilisions vos données biométriques dérivées de votre image de
                vérification, de votre (vos) document(s) d'identification et de
                votre (vos) image(s) photographique(s) connue(s) ou précédemment
                vérifiée(s) qui nous a (ont) été fournie(s) par l'entité de
                vérification (votre "image d'identification"). Nous utiliserons
                votre IT2 dérivé de votre image de vérification, de votre (vos)
                document(s) d'identification et de votre image d'identification
                afin de vérifier et d'authentifier votre identité comme étant
                celle de la personne représentée sur l'image de vérification et
                sur le(s) document(s) d'identification ou sur l'image de
                vérification et sur l'image d'identification, selon la manière
                dont l'entité de vérification nous a demandé de vérifier votre
                identité. Vous n'êtes pas tenu de donner votre consentement et
                nous ne collecterons, ne traiterons, ne stockerons ni
                n'utiliserons vos données biométriques ou votre IT2 sans votre
                consentement. Toutefois, si vous choisissez de ne pas donner
                votre consentement, vous ne pourrez pas vérifier et authentifier
                votre identité auprès de l'entité de vérification en utilisant
                les services, ce qui peut affecter vos interactions ultérieures
                avec l'entité de vérification. Vous devrez interagir directement
                avec l'entité de vérification pour résoudre ces problèmes.
                <br />
                <br />
                Sauf disposition contraire dans les présentes conditions, nous
                ne divulguerons ni ne diffuserons vos données biométriques ou
                IT2 à quiconque autre que l'entité de vérification et nos
                filiales et sociétés affiliées sans avoir obtenu au préalable
                votre consentement écrit à une telle divulgation ou diffusion,
                sauf si une telle divulgation est:
                <br />
                <ol>
                  <li>
                    <p>
                      exigée par une loi fédérale, étatique ou provinciale ou
                      par une ordonnance municipale ;
                    </p>
                  </li>
                  <li>
                    <p>
                      pour signaler une utilisation frauduleuse ou une tentative
                      d'utilisation frauduleuse des services aux autorités
                      judiciaires compétentes ou
                    </p>
                  </li>
                  <li>
                    <p>
                      exigée en vertu d'un ordre, d'une demande, d'une
                      ordonnance, d'une citation à comparaître, d'un mandat ou
                      d'une assignation valide et écrite émanant d'un tribunal
                      compétent ou d'un autre organisme gouvernemental
                      légalement habilité à délivrer ce type de procédure
                      légale.
                    </p>
                  </li>
                </ol>
                Une copie des présentes conditions est disponible à l'adresse
                suivante :{ ' ' }
                <a
                  href="https://truststamp.ai/TermsAndConditions.html?lang=fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://truststamp.ai/TermsAndConditions.html?lang=fr
                </a>
              </p>
            </b>
          </li>

          <li>
            <p>
              <u>Divulgation des données à l'entité de vérification.</u>
              Une entité de vérification peut souhaiter conserver votre image de
              vérification, votre image de document d'identité, les données
              extraites et l'IT2 (ci-après dénommées "vos données") afin de
              vérifier votre identité à l'avenir. Vous nous autorisez à
              divulguer votre IT2 à une entité de vérification à cette fin. Vous
              consentez à ce que cette entité de vérification partage vos
              données avec nous à l'avenir et à ce que nous utilisions vos
              données pour : (i) vérifier votre identité et (ii) détecter des
              activités illégales ou frauduleuses. Vous comprenez et acceptez
              qu'une fois transférées à une entité de vérification, la
              conservation, le stockage et l'utilisation de vos données seront
              soumis à toutes les conditions et à tous les accords conclus entre
              vous et cette entité de vérification. Vous acceptez que nous ne
              soyons pas responsables des actes de cette entité de vérification
              en ce qui concerne vos données telles qu'elles ont été divulguées
              à l'entité de vérification.
            </p>
          </li>

          <li>
            <p>
              <u>
                Consentement au traitement et le partage de l'image de
                vérification et des données relatives aux documents
                d'identification.
              </u>
              En fournissant votre image de vérification, vous donnez
              expressément votre consentement à l'utilisation, au traitement et
              au partage de votre image de vérification avec l'entité de
              vérification à des fins de vérification d'identité. Vous
              garantissez et déclarez que l'image téléchargée est la vôtre et
              non celle d'un tiers. Vous confirmez que vous disposez de tous les
              droits et autorisations nécessaires pour soumettre cette image aux
              fins indiquées. En fournissant une image de votre (vos)
              document(s) d'identification, vous déclarez que vous êtes le
              propriétaire et l'utilisateur légitime de ce(s) document(s)
              d'identification et que vous consentez à ce que nous utilisions la
              reconnaissance optique de caractères (ROC), le balayage numérique
              et par radiofréquence, ainsi que d'autres technologies pour lire
              toutes les données contenues dans ou sur votre document
              d'identification, qui peuvent inclure, sans s'y limiter, votre
              nom, votre adresse, votre âge, votre date de naissance, votre lieu
              de naissance, votre statut de citoyen, la couleur de vos yeux, la
              couleur de vos cheveux, votre taille, votre poids, votre race,
              votre appartenance ethnique, le numéro de votre document
              d'identité, la date de sa délivrance ou le lieu de sa délivrance
              (les "informations d'identification"). En soumettant une image de
              votre pièce d'identité, vous reconnaissez également que vous
              comprenez et acceptez que (1) nous puissions partager vos
              informations d'identification, y compris l'image de votre pièce
              d'identité, avec l'entité chargée de la vérification, (2)
              l'utilisation ultérieure et la disposition de vos informations
              d'identification que nous transférons à l'entité chargée de la
              vérification seront régies uniquement par les conditions générales
              et les accords conclus entre vous et cette entité, (3) dans la
              mesure où cela est légal, et sans vous identifier en tant que
              sujet de ces données, nous pouvons utiliser vos données à des fins
              d'amélioration, de développement et de formation de nos systèmes
              et services. Cela comprend, sans s'y limiter, l'utilisation des
              données dans des modèles d'apprentissage automatique, l'analyse
              des données et d'autres processus de recherche et de développement
              et (4) sous réserve des limitations de responsabilité
              supplémentaires ci-dessous, vous, en votre nom propre et au nom de
              vos héritiers et ayants droit, acceptez d'indemniser,
              d'indemniser, de libérer et de dégager de toute responsabilité
              Trust Stamp de toute réclamation liée à l'utilisation ultérieure
              et à la disposition de vos informations d'identification, y
              compris, sans limitation, toute réclamation fondée sur (a)
              l'utilisation inappropriée, la divulgation ou la disposition de
              vos informations d'identification, (b) la fraude et le vol
              d'identité, (c) l'atteinte à la vie privée, ou (d) l'infliction
              négligente ou intentionnelle d'une détresse émotionnelle.{ ' ' }
              <b>
                Si vous n'acceptez pas ces limitations, vous ne devez PAS
                soumettre une image de votre document d'identification.
              </b>
            </p>
          </li>

          <li>
            <p>
              <u>La conservation des données.</u>
              Vos données peuvent être conservées conformément à nos obligations
              envers une entité de vérification. Les entités de vérification
              fixent une période de conservation de tout ou partie de vos
              données. En l'absence d'une décision de justice, d'une citation à
              comparaître, d'un mandat, d'une ordonnance ou de toute autre
              exigence légale, nous ne conserverons en aucun cas vos données
              biométriques, IT2 et informations d'identification pendant plus de
              trente-six (36) mois. Dans l'intervalle, vous pouvez nous demander
              de supprimer vos données. Pour demander la suppression de vos
              données, envoyez une demande par courrier électronique à{ ' ' }
              <a href="mailto:datadeletion@truststamp.ai">
                datadeletion@truststamp.ai{ ' ' }
              </a>{ ' ' }
              ou par courrier à l'adresse suivante:
              <br />
              <br />
              Suppression des données : Trust Stamp <br />
              1er et 2e étages, 3017 Bolling Way NE
              <br />
              Atlanta, GA, 30305
              <br />
              <br />
              Une demande de suppression de vos données biométriques ou de votre
              IT2 n'affectera pas une vérification effectuée. La suppression de
              vos données biométriques ou de votre IT2 peut prendre jusqu'à dix
              jours ouvrables à compter de la réception de la demande.
            </p>
          </li>

          <li>
            <p>
              <u>Données relatives à l'appareil et à la session.</u>
              Nous pouvons également recueillir des données sur l'appareil à
              partir duquel le document d'identification nous a été transmis
              ("données relatives à l'appareil") et sur la session de connexion
              Internet au cours de laquelle votre document d'identification,
              votre image d'identification ou votre image de vérification nous a
              été transmise, y compris l'adresse IP ("données relatives à la
              session"). Vous consentez à ce que nous saisissions et conservions
              les données relatives à l'appareil et les données relatives à la
              session, et à ce que nous partagions ces informations avec
              l'entité de vérification.
            </p>
          </li>

          <li>
            <p>
              <u>Contenu.</u>
              Vous acceptez que, conformément aux présentes Conditions, et sur
              la base de vos garanties expresses concernant les données que vous
              envoyez ou recevez par le biais de l'utilisation des Services,
              Trust Stamp n'a ni n'exerce aucun contrôle éditorial ou autre
              contrôle subjectif sur le contenu des données, des communications
              ou des messages qui peuvent être envoyés par l'Entité de
              vérification. Trust Stamp n'exerce aucun contrôle sur les
              informations retransmises par l'Entité de vérification, et ne peut
              être tenu responsable de l'exactitude, de la justesse ou de la
              légalité de ces informations. Vous êtes seul responsable de la
              vérification de l'exactitude, de la légalité et de l'adéquation de
              toute information et de tout service que vous obtenez d'une Entité
              de vérification ou de tout tiers via ou par le biais de votre
              utilisation des Services.
            </p>
          </li>

          <li>
            <p>
              <u>Stockage des données.</u>
              Nous ferons preuve d'une diligence raisonnable pour stocker,
              transmettre et protéger de la divulgation toutes les données
              électroniques, y compris les données biométriques, recueillies
              auprès de vous. Ce stockage, cette transmission et cette
              protection contre la divulgation seront effectués d'une manière
              identique ou plus protectrice que la manière dont nous stockons,
              transmettons et protégeons de la divulgation d'autres informations
              confidentielles et sensibles.
            </p>
          </li>
          <li>
            <p>
              <u>Aucune garantie de service.</u>
              Notre objectif est de toujours rendre les services disponibles.
              Toutefois, il peut arriver que nous devions modifier ou désactiver
              les services de manière temporaire ou permanente. Nous nous
              réservons le droit d'interrompre, de modifier, de suspendre ou de
              désactiver, en tout ou en partie, l'accès aux services ou
              d'imposer des limites d'utilisation ou d'accès aux services, avec
              ou sans préavis, et dans tous les cas sans responsabilité. Nous ne
              pouvons en aucun cas être tenus responsables de l'interruption, de
              la modification, de la suspension ou de la désactivation de
              l'accès aux services, à quelque moment que ce soit et pour quelque
              durée que ce soit.
            </p>
          </li>
          <li>
            <p>
              <u>Utilisation légale uniquement.</u>
              Vous ne pouvez utiliser les services qu'à des fins légales et
              conformément aux présentes conditions. Nous ne sommes pas tenus de
              faire appliquer les conditions en votre nom à l'encontre d'un
              autre utilisateur final, d'une personne ou d'une entité. Vous
              acceptez de ne pas aider, encourager ou permettre à d'autres
              personnes d'utiliser les services :
            </p>
            <ol type="a">
              <li>
                <p>
                  à des fins autres que celles expressément autorisées par les
                  présentes conditions;
                </p>
              </li>
              <li>
                <p>
                  d'une manière abusive, diffamatoire, injurieuse, menaçante,
                  portant atteinte au droit à la vie privée d'une autre
                  personne, ou dont on pourrait raisonnablement attendre qu'elle
                  cause un préjudice à une personne ou à une entité ;
                </p>
              </li>
              <li>
                <p>
                  de violer toute loi ou réglementation nationale, régionale,
                  fédérale, étatique, locale ou internationale applicable, ou
                  d'encourager toute activité illégale ;
                </p>
              </li>
              <li>
                <p>
                  de créer, d'envoyer, de recevoir sciemment, d'afficher, de
                  transmettre ou d'utiliser tout matériel qui :
                </p>
                <ol type="i">
                  <li>
                    <p>
                      enfreint un brevet, une marque, un secret commercial, un
                      droit d'auteur ou tout autre droit de propriété
                      intellectuelle d'une autre personne ;
                    </p>
                  </li>
                  <li>
                    <p>
                      contient des images de pornographie enfantine ou de
                      matériel d'abus sexuel d'enfants ; ou{ ' ' }
                    </p>
                  </li>
                  <li>
                    <p>
                      viole ou contribue à violer les droits légaux (y compris
                      les droits de publicité et de respect de la vie privée)
                      d'autrui, ou contient des éléments susceptibles d'engager
                      la responsabilité civile ou pénale d'une personne en vertu
                      de la législation ou de la réglementation en vigueur, ou
                      qui sont en contradiction avec les présentes conditions ou
                      avec notre politique de protection de la vie privée, ou
                      qui sont susceptibles de tromper ou d'induire en erreur
                      une personne quelconque ;
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  violer les présentes conditions ou toute autre règle ou
                  politique que nous avons publiée sur notre site web ou qui
                  vous a été communiquée d'une autre manière ;
                </p>
              </li>
              <li>
                <p>
                  transmettre des virus informatiques, des vers, des défauts,
                  des chevaux de Troie ou d'autres logiciels malveillants ; ou
                </p>
              </li>
              <li>
                <p>
                  d'obtenir un accès non autorisé à un réseau informatique ou
                  d'en violer la sécurité de quelque manière que ce soit.
                </p>
              </li>
            </ol>
            <p>
              Vous vous engagez à ne pas aider, encourager ou permettre à
              d'autres personnes de :
            </p>
            <ol type="a" start="8">
              <li>
                <p>
                  faire de l'ingénierie inverse, décompiler, assembler à
                  l'envers ou effectuer toute autre opération sur ou avec les
                  services qui révélerait ou divulguerait un code source, des
                  secrets commerciaux ou d'autres informations exclusives ;
                </p>
              </li>
              <li>
                <p>
                  modifier, adapter, s'approprier, reproduire, distribuer,
                  traduire, créer des œuvres dérivées ou des adaptations,
                  afficher publiquement, vendre, échanger ou exploiter de
                  quelque manière que ce soit les services, sauf autorisation
                  expresse de notre part ;
                </p>
              </li>
              <li>
                <p>
                  supprimer ou modifier tout avis de droit d'auteur, de marque
                  ou d'autres droits de propriété figurant sur toute partie des
                  services ou sur tout document imprimé ou copié à partir des
                  services ;
                </p>
              </li>
              <li>
                <p>
                  enregistrer, traiter, récolter, collecter ou extraire des
                  informations sur d'autres utilisateurs ;
                </p>
              </li>
              <li>
                <p>
                  utiliser un robot, un script ou tout autre processus ou moyen
                  automatique pour accéder aux services à quelque fin que ce
                  soit ;
                </p>
              </li>
              <li>
                <p>
                  supprimer, contourner, désactiver, endommager ou interférer de
                  toute autre manière avec les dispositifs de sécurité ou autres
                  restrictions des services ;
                </p>
              </li>
              <li>
                <p>
                  tenter d'obtenir un accès non autorisé, d'interférer,
                  d'endommager ou de perturber toute partie des services, le
                  serveur sur lequel les services sont stockés ou tout serveur,
                  ordinateur ou base de données connecté aux services ; attaquer
                  les services par le biais d'un déni de service, d'un déni de
                  service distribué (DDoS) ou de toute autre forme de
                  cyberattaque ; ou tenter de toute autre manière d'interférer
                  avec le bon fonctionnement des services.
                </p>
              </li>
            </ol>
            <p>
              Les restrictions susmentionnées ne s'appliquent que dans la mesure
              permise par la loi applicable. Néanmoins, vous acceptez de ne pas
              agir contrairement à ces restrictions (même si vos actions sont
              par ailleurs légales) sans nous avertir par écrit trente (30)
              jours à l'avance à l'adresse{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>{ ' ' }
              de votre intention de le faire, ainsi que de toute information que
              nous pourrions raisonnablement exiger pour nous donner l'occasion
              d'accommoder ou de traiter autrement votre action proposée, ce qui
              sera à notre seule et entière discrétion.
            </p>
          </li>
          <li>
            <p>
              <u>Soumissions frauduleuses.</u>
              Toute soumission ou tentative de soumission d'une image de
              vérification, d'une image d'identification ou d'un document
              d'identification qui est frauduleuse ou soumise dans le but de
              commettre une fraude, y compris, mais sans s'y limiter, des
              actions en violation du paragraphe 11 ci-dessus, sera considérée
              comme une soumission frauduleuse. Nonobstant les conditions
              ci-dessus, si vous faites une soumission frauduleuse, vous
              acceptez que nous puissions conserver, traiter, stocker et
              utiliser toutes les données soumises, y compris les données
              biométriques et les informations d'identification, pendant une
              période pouvant aller jusqu'à dix (10) ans à des fins (i)
              d'enquête et de poursuite des activités frauduleuses et (ii) de
              formation et d'amélioration de nos systèmes en matière de
              détection et de prévention de la fraude. Sur demande écrite
              adressée à{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>
              , vous pouvez demander que vos données ne soient pas utilisées à
              cette fin. Toutefois, pour accéder à votre demande, nous pouvons
              exiger que vous nous fournissiez des documents établissant votre
              identité en tant que propriétaire légitime de toute donnée
              associée à une soumission frauduleuse.
            </p>
          </li>
          <li>
            <p>
              <u>Frais de citation à comparaître.</u>
              Si nous sommes tenus de fournir des informations en réponse à une
              décision de justice, une citation à comparaître, un mandat, une
              injonction ou une demande juridique ou gouvernementale similaire
              liée à votre utilisation des services, nous pouvons vous facturer
              nos frais. Ces frais peuvent inclure le temps raisonnable consacré
              par les avocats et les employés à la recherche des dossiers, à la
              préparation des documents et à la participation à une déposition
              ou à une audience du tribunal.
            </p>
          </li>
          <li>
            <p>
              <u>Propriété intellectuelle.</u>
              Vous conservez tous les droits, titres et intérêts relatifs au
              matériel, au contenu, aux données et aux informations (y compris
              vos informations personnelles) que vous nous soumettez lors de
              l'utilisation des services (collectivement, votre "contenu"). Sous
              réserve des présentes conditions, vous nous autorisez à utiliser
              ou à divulguer votre contenu uniquement dans la mesure où cela est
              nécessaire pour vous fournir les services ou dans la mesure où
              cela est autorisé par les présentes conditions. Vous déclarez et
              garantissez que:
            </p>
            <ol type="a">
              <li>
                <p>
                  vous possédez ou avez obtenu d'une autre manière tous les
                  droits, décharges et autorisations nécessaires pour soumettre
                  l'ensemble de votre contenu aux services et pour accorder les
                  droits qui nous sont accordés dans les présentes conditions ;
                </p>
              </li>
              <li>
                <p>
                  vous ne soumettrez ni ne tenterez d'autoriser la collecte, le
                  traitement, le stockage ou l'utilisation de données
                  biométriques provenant d'une image ou d'une photo d'identité
                  sur laquelle vous n'êtes pas la personne représentée ; et
                </p>
              </li>
              <li>
                <p>
                  votre contenu, sa soumission et son utilisation telles que
                  vous les autorisez dans les présentes conditions ne violeront
                  (i) aucune loi ou réglementation applicable ou (ii) aucun
                  droit de tiers en matière de propriété intellectuelle, de
                  confidentialité, de publicité ou autre.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>
                <u>Résiliation des services.</u>
                Trust Stamp peut mettre fin à votre accès à la totalité ou à une
                partie des services en tout temps, avec ou sans motif, avec ou
                sans préavis, avec effet immédiat. Si vous souhaitez résilier
                votre inscription et votre compte, vous pouvez le faire à tout
                moment en nous contactant à{ ' ' }
                <a href="mailto:support@truststamp.net">
                  support@truststamp.net
                </a>
                . En cas de résiliation, tous les droits et obligations des
                parties cesseront et vous cesserez immédiatement d'utiliser les
                services, à l'exception (a) de toutes les obligations
                contractées avant la date effective de résiliation, (b) de tous
                les recours en cas de violation des présentes conditions et (c)
                des dispositions des articles 4, 5, 12, 13, 16, 17, 18, 19, 20
                et 21 qui resteront en vigueur.
              </b>
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>EXCLUSION DES GARANTIES.</b>
              </u>
              TRUST STAMP DÉCLINE PAR LES PRÉSENTES TOUTE GARANTIE DE QUELQUE
              NATURE QUE CE SOIT, EXPRESSE, IMPLICITE, STATUTAIRE OU AUTRE, Y
              COMPRIS, MAIS SANS S'Y LIMITER, TOUTE GARANTIE D'EXACTITUDE, DE
              QUALITÉ MARCHANDE, D'ABSENCE DE CONTREFAÇON, DE JOUISSANCE
              PAISIBLE OU D'ADÉQUATION À UN USAGE PARTICULIER. DANS TOUTE LA
              MESURE PERMISE PAR LA LOI APPLICABLE, LES SERVICES SONT FOURNIS
              EXCLUSIVEMENT "EN L'ÉTAT" ET "SELON LA DISPONIBILITÉ". TRUST STAMP
              NE GARANTIT PAS L'EXACTITUDE, L'OPPORTUNITÉ, L'EXHAUSTIVITÉ OU
              L'UTILITÉ DES SERVICES OU DE TOUT CONTENU. VOUS ACCEPTEZ
              D'UTILISER LES SERVICES À VOS PROPRES RISQUES. TRUST STAMP NE
              GARANTIT PAS QU'IL N'Y AURA PAS D'INTERFÉRENCE AVEC VOTRE
              JOUISSANCE DES SERVICES, QUE LES FONCTIONS CONTENUES DANS LES
              SERVICES, OU LES SERVICES EXÉCUTÉS OU FOURNIS PAR OU À TRAVERS LES
              SERVICES, RÉPONDRONT À VOS EXIGENCES, QUE LE FONCTIONNEMENT DES
              SERVICES SERA ININTERROMPU OU SANS ERREUR, OU QUE LES DÉFAUTS DES
              SERVICES SERONT CORRIGÉS. SANS LIMITER LA PORTÉE DE CE QUI
              PRÉCÈDE, TRUST STAMP DÉCLINE TOUTE GARANTIE DÉCOULANT DE LA
              CONDUITE DES AFFAIRES, DE L'EXÉCUTION OU DE L'USAGE COMMERCIAL.
              AUCUNE INFORMATION ORALE OU ÉCRITE FOURNIE PAR TRUST STAMP OU
              SON/SES REPRÉSENTANT(S) NE SERA CONSIDÉRÉE COMME CRÉANT UNE
              GARANTIE. SI VOUS N'ÊTES PAS SATISFAIT DES SERVICES OU DE L'UNE DE
              CES CONDITIONS, VOTRE SEUL RECOURS EST DE CESSER D'UTILISER LES
              SERVICES. CERTAINES JURIDICTIONS N'AUTORISENT PAS L'EXCLUSION DES
              GARANTIES IMPLICITES OU LA LIMITATION DES DROITS STATUTAIRES
              APPLICABLES À UN CONSOMMATEUR. SI VOUS RÉSIDEZ DANS UNE TELLE
              JURIDICTION, L'EXCLUSION ET LES LIMITATIONS CI-DESSUS PEUVENT NE
              PAS S'APPLIQUER À VOUS.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>LIMITATION DE RESPONSABILITÉ.</b>
              </u>
              VOUS RECONNAISSEZ QUE VOTRE ACCÈS AUX SERVICES ET LEUR
              UTILISATION, AINSI QUE LA CONFIANCE QUE VOUS LEUR ACCORDEZ, SE
              FONT À VOS RISQUES ET PÉRILS. DANS LA MESURE MAXIMALE AUTORISÉE
              PAR LA LOI, EN AUCUN CAS TRUST STAMP OU L'UN DE SES PROPRIÉTAIRES,
              GESTIONNAIRES, DIRIGEANTS, ADMINISTRATEURS, MEMBRES, ACTIONNAIRES,
              SOCIÉTÉS AFFILIÉES, FILIALES, EMPLOYÉS, ENTREPRENEURS, AGENTS OU
              REPRÉSENTANTS NE PEUVENT ÊTRE TENUS RESPONSABLES DES DOMMAGES
              CONSÉCUTIFS, EXEMPLAIRES, INDIRECTS, ACCESSOIRES OU SPÉCIAUX, Y
              COMPRIS, SANS S'Y LIMITER, LES DOMMAGES POUR PERTE DE BÉNÉFICES,
              L'EXPOSITION OU LA PERTE DE DONNÉES, L'INTERRUPTION D'ACTIVITÉ
              ET/OU TOUT AUTRE DOMMAGE OU PERTE COMMERCIAL, DÉCOULANT DE OU EN
              RAPPORT AVEC LES SERVICES, QUE TRUST STAMP AIT ÉTÉ OU NON INFORMÉ
              DE LA POSSIBILITÉ DE TELS DOMMAGES, SUR TOUTE THÉORIE DE
              RESPONSABILITÉ (Y COMPRIS LE CONTRAT, LA NÉGLIGENCE DÉLICTUELLE OU
              AUTRE) DÉCOULANT DE, EN RAPPORT AVEC OU RÉSULTANT DES SERVICES.
              VOUS RECONNAISSEZ SPÉCIFIQUEMENT QUE LES TEMPS D'ARRÊT DES
              ORDINATEURS ET LES VIRUS SONT DES RISQUES INHÉRENTS À
              L'UTILISATION D'INTERNET ET DE TOUT PRODUIT LOGICIEL, ET VOUS
              ACCEPTEZ D'ASSUMER LA RESPONSABILITÉ DE TOUT PRÉJUDICE OU DOMMAGE,
              DE QUELQUE NATURE QUE CE SOIT, RÉSULTANT DE CES PRÉJUDICES
              ÉVENTUELS. VOUS RECONNAISSEZ AUSSI SPÉCIFIQUEMENT QUE VOUS POUVEZ
              DIVULGUER DES INFORMATIONS SENSIBLES, PRIVÉES ET CONFIDENTIELLES,
              Y COMPRIS DES DONNÉES ET DES INFORMATIONS BIOMÉTRIQUES, VOUS
              CONCERNANT DANS LE CADRE DE VOTRE UTILISATION DES SERVICES ET VOUS
              ACCEPTEZ D'ASSUMER LA RESPONSABILITÉ DE TOUT PRÉJUDICE OU DOMMAGE,
              DE QUELQUE NATURE QUE CE SOIT, RÉSULTANT DE LA DIVULGATION D'UN
              TEL CONTENU. EN AUCUN CAS, NOTRE RESPONSABILITÉ TOTALE ENVERS VOUS
              POUR TOUS LES DOMMAGES (SAUF SI LA LOI APPLICABLE L'EXIGE) NE
              DÉPASSERA LE MONTANT LE PLUS ÉLEVÉ ENTRE (A) LE MONTANT QUE VOUS
              AVEZ PAYÉ POUR LES SERVICES AU COURS DES SIX (6) MOIS PRÉCÉDANT LA
              RÉCLAMATION APPLICABLE, LE CAS ÉCHÉANT, OU (B) CENT DOLLARS
              (100,00 $). VOUS ET NOUS AVONS L'INTENTION DE FAIRE EN SORTE QUE
              CETTE DISPOSITION SOIT INTERPRÉTÉE PAR UN TRIBUNAL COMME ÉTANT LA
              LIMITATION DE RESPONSABILITÉ LA PLUS LARGE AUTORISÉE PAR LA LOI
              APPLICABLE. CERTAINES JURIDICTIONS N'AUTORISENT PAS LA LIMITATION
              OU L'EXCLUSION DES DOMMAGES ACCESSOIRES, INDIRECTS OU D'AUTRES
              TYPES DE DOMMAGES, DE SORTE QUE CERTAINES DES LIMITATIONS
              CI-DESSUS PEUVENT NE PAS S'APPLIQUER À VOUS. CES LIMITATIONS
              S'APPLIQUERONT MÊME SI LE RECOURS SUSMENTIONNÉ N'ATTEINT PAS SON
              OBJECTIF ESSENTIEL.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>ARBITRAGE OBLIGATOIRE ET CONTRAIGNANT.</b>{ ' ' }
              </u>
              TOUT LITIGE, DIFFÉREND, CONTROVERSE OU RÉCLAMATION DÉCOULANT DES
              PRÉSENTES CONDITIONS OU DE VOTRE UTILISATION DES SERVICES, OU S'Y
              RAPPORTANT, SERA RÉSOLU PAR UN ARBITRAGE DÉFINITIF ET CONTRAIGNANT
              CONFORMÉMENT AUX RÈGLES DES SERVICES DE MÉDIATION ET D'ARBITRAGE
              HENNING À ATLANTA, EN GÉORGIE. L'ARBITRAGE SE DÉROULERA EN
              PRÉSENCE D'UN SEUL ARBITRE. L'ARBITRE PEUT ACCORDER DES FRAIS
              ET/OU DES HONORAIRES D'AVOCAT À LA PARTIE GAGNANTE. VOUS COMPRENEZ
              QUE L'ARBITRAGE EST DÉFINITIF ET CONTRAIGNANT ET QUE VOUS RENONCEZ
              À VOS DROITS À D'AUTRES PROCÉDURES DE RÉSOLUTION (TELLES QU'UNE
              ACTION EN JUSTICE OU UNE PROCÉDURE ADMINISTRATIVE).
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>
                  RENONCIATION AUX RECOURS COLLECTIFS ET AUX PROCÈS AVEC JURY.
                </b>
              </u>
              EN CE QUI CONCERNE VOTRE UTILISATION DES SERVICES, QUE VOUS AYEZ
              OBTENU OU UTILISÉ LES SERVICES À DES FINS PERSONNELLES,
              COMMERCIALES OU AUTRES, TOUTES LES RÉCLAMATIONS DOIVENT ÊTRE
              INTRODUITES À TITRE INDIVIDUEL ET NON EN TANT QUE PLAIGNANT OU
              MEMBRE D'UN GROUPE DANS LE CADRE D'UNE PRÉTENDUE ACTION
              COLLECTIVE, D'UN RECOURS COLLECTIF, D'UNE ACTION DE PROCUREUR
              GÉNÉRAL PRIVÉ OU D'UNE AUTRE PROCÉDURE REPRÉSENTATIVE. CETTE
              RENONCIATION S'APPLIQUE ÉGALEMENT À L'ARBITRAGE COLLECTIF, ET UN
              ARBITRE NE PEUT PAS CONSOLIDER LES RÉCLAMATIONS DE PLUS D'UNE
              PERSONNE DANS UNE ACTION. VOUS RECONNAISSEZ QU'EN CONCLUANT CES
              CONDITIONS, VOUS ET TRUST STAMP RENONCEZ CHACUN AU DROIT À UN
              PROCÈS DEVANT JURY OU À LA PARTICIPATION À UNE ACTION COLLECTIVE,
              À UN RECOURS COLLECTIF, À UNE ACTION DE PROCUREUR GÉNÉRAL PRIVÉ OU
              À TOUTE AUTRE PROCÉDURE REPRÉSENTATIVE DE QUELQUE NATURE QUE CE
              SOIT.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>INDEMNISATION.</b>
              </u>
              VOUS ACCEPTEZ D'INDEMNISER ET DE DÉGAGER DE TOUTE RESPONSABILITÉ
              TRUST STAMP, SES FILIALES ET SOCIÉTÉS AFFILIÉES, AINSI QUE LEURS
              PROPRIÉTAIRES, DIRECTEURS, ADMINISTRATEURS, MEMBRES, ACTIONNAIRES,
              EMPLOYÉS, CONTRACTANTS, AGENTS, CONCÉDANTS DE LICENCE ET
              FOURNISSEURS RESPECTIFS, EN CAS DE RÉCLAMATIONS, RESPONSABILITÉS,
              DOMMAGES, JUGEMENTS, RÉCOMPENSES, PERTES, COÛTS, DÉPENSES ET/OU
              FRAIS (Y COMPRIS LES FRAIS D'AVOCAT RAISONNABLES) RÉSULTANT (A) DE
              VOTRE VIOLATION DES PRÉSENTES CONDITIONS ; (B) DE VOTRE
              UTILISATION OU ACCÈS AUX SERVICES ; (C) DE VOTRE VIOLATION DES
              DROITS D'UN TIERS, Y COMPRIS, MAIS SANS S'Y LIMITER, DES DROITS DE
              PROPRIÉTÉ INTELLECTUELLE OU DES DROITS DE PUBLICITÉ ; OU (D) DE
              TOUTE RÉCLAMATION SELON LAQUELLE VOTRE UTILISATION DES SERVICES A
              CAUSÉ DES DOMMAGES À UN TIERS.
            </p>
          </li>
          <li>
            <p>
              <u>Modification des conditions.</u>
              Nous nous réservons le droit de modifier les présentes conditions
              et d'ajouter des conditions nouvelles ou supplémentaires à votre
              utilisation des services, pour quelque raison que ce soit, sans
              préavis et à tout moment. Ces modifications et conditions
              supplémentaires entreront en vigueur immédiatement et seront
              intégrées aux présentes conditions. La poursuite de votre
              utilisation des services vaudra acceptation des présentes
              conditions. Nous vous informerons des modifications importantes en
              vous demandant de les accepter avant toute utilisation future des
              services. Les conditions actuelles seront également disponibles
              sur notre site web. Ce qui constitue des "changements importants"
              sera déterminé à notre seule discrétion, en toute bonne foi et en
              faisant preuve de bon sens et d'un jugement raisonnable.
            </p>
          </li>
          <li>
            <p>
              <u>Conditions générales. </u>
              Les présentes conditions constituent la seule et entière entente
              entre vous et Trust Stamp relativement aux services et remplacent
              toutes les ententes, tous les accords, toutes les déclarations et
              toutes les garanties antérieurs et contemporains, tant écrits
              qu'oraux, relativement aux services. Aucune renonciation de notre
              part aux présentes Conditions ne sera considérée comme une
              renonciation ultérieure ou continue à ce terme ou à cette
              condition ou à tout autre terme ou condition, et le fait que nous
              ne fassions pas valoir un droit ou une disposition en vertu des
              présentes Conditions ou que nous tardions à le faire ne
              constituera pas une renonciation à ce droit ou à cette
              disposition. Si une disposition des présentes conditions est jugée
              invalide, illégale ou inapplicable par un tribunal compétent pour
              quelque raison que ce soit, cette disposition sera modifiée pour
              refléter l'intention des parties ou éliminée dans la mesure
              minimale de sorte que les autres dispositions des conditions
              resteront pleinement en vigueur. Les conditions, ainsi que les
              droits ou obligations qui en découlent, ne peuvent être cédés,
              transférés ou faire l'objet d'une sous-licence par vous, sauf avec
              notre consentement écrit préalable, mais peuvent être cédés ou
              transférés par nous sans restriction. Toute tentative de cession
              de votre part constitue une violation des présentes conditions et
              est nulle. Les titres de section dans les présentes Conditions
              sont uniquement pour des raisons de commodité et n'ont aucun effet
              juridique ou contractuel. Vous accordez par les présentes à Trust
              Stamp le droit de prendre les mesures raisonnablement nécessaires
              ou appropriées pour faire respecter et/ou vérifier la conformité à
              toute partie des présentes conditions. Vous acceptez que nous
              ayons le droit, sans responsabilité envers vous, de divulguer
              toute donnée et/ou information aux autorités chargées de
              l'application de la loi, aux représentants du gouvernement et/ou à
              un tiers, si nous le jugeons raisonnablement nécessaire ou
              approprié pour appliquer et/ou vérifier la conformité à toute
              partie des présentes Conditions (y compris, mais sans s'y limiter,
              notre droit de coopérer avec toute procédure judiciaire relative à
              votre utilisation des Services, et/ou à une réclamation d'un tiers
              selon laquelle votre utilisation des Services est illégale et/ou
              enfreint les droits de ce tiers).
            </p>
          </li>
          <li>
            <p>
              <u>Droit applicable et juridiction compétente.</u>
              Les présentes conditions et la licence qui y est accordée sont
              régies par le droit de l'État de Géorgie, sans tenir compte des
              principes relatifs aux conflits de lois. Pour toute réclamation à
              laquelle la clause d'arbitrage obligatoire contenue dans les
              présentes est jugée inapplicable, vous acceptez de vous soumettre
              à la juridiction personnelle des tribunaux fédéraux et d'État
              situés à Atlanta, en Géorgie. Votre utilisation des services peut
              également être soumise à d'autres lois locales, étatiques,
              fédérales ou internationales.
            </p>
            <b>
              <p>
                En fournissant volontairement une image de vérification ou un
                document d'identification, vous reconnaissez et acceptez les
                présentes conditions:
              </p>

              <ol type="a">
                <li>
                  <p>
                    vous déclarez avoir lu, compris et accepté les présentes
                    conditions dans leur intégralité, y compris toutes les
                    dispositions relatives à la collecte, au traitement, au
                    stockage et à l'utilisation de vos données biométriques ;
                  </p>
                </li>
                <li>
                  <p>
                    déclarez que vous êtes la personne représentée dans l'Image
                    de Vérification ; l'Image de Vérification est une image
                    vivante et non modifiée de vous, capturée par la caméra de
                    l'appareil utilisé dans le processus (c'est-à-dire pas, par
                    exemple, une image d'une image ou une vidéo d'une vidéo) ;
                    votre Document d'Identification est un document légalement
                    produit et valablement délivré par la juridiction qui y
                    figure et sur lequel votre image et d'autres données ne sont
                    pas modifiées ;
                  </p>
                </li>
                <li>
                  <p>
                    nous désigner comme votre agent dans le seul but d'apposer
                    votre signature électronique sur les présentes conditions en
                    votre nom et nous demander d'apposer et d'associer votre
                    adresse IP ainsi que la date et l'heure de votre prise de
                    connaissance des présentes conditions en tant que signature
                    électronique juridiquement contraignante attestant de votre
                    compréhension, de votre reconnaissance écrite et de votre
                    acceptation des présentes conditions;
                  </p>
                </li>
                <li>
                  <p>
                    accepter que votre utilisation continue des services
                    constitue votre consentement permanent à ces conditions.
                  </p>
                </li>
              </ol>
            </b>
          </li>
        </ol>
      </div>
    );
  }
}
