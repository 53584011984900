import React, { Component } from 'react';

export default class Es extends Component {
  render() {
    return (
      <div>
        <h1>
          <u>DESARROLLADO POR TRUST STAMP TÉRMINOS Y CONDICIONES</u>
        </h1>

        <p>
          <b>Quién es usted?</b>
        </p>
        <p>
          T Stamp Inc. d/b/a Trust Stamp (en lo sucesivo, "Trust Stamp",
          "nosotros" o "nos") es una empresa de inteligencia artificial centrada
          en proporcionar una mayor seguridad a través de la autenticación y
          verificación biométrica de la identidad. "Powered by Trust Stamp" es
          un servicio que Trust Stamp proporciona a nuestros clientes (cada uno
          de ellos una "Entidad verificadora" y colectivamente, "Entidades
          verificadoras") que permite a la Entidad verificadora verificar de
          forma probabilística la identidad de su cliente (un "Usuario final") a
          través de los procesos de autenticación segura de Trust Stamp.{ ' ' }
        </p>

        <p>
          <b>Cómo funciona?</b>
        </p>
        <p>
          "Powered by Trust Stamp" autentica la identidad de un Usuario Final
          mediante la comparación de datos biométricos derivados de una imagen o
          vídeo del Usuario Final con datos derivados de imagen(es) conocida(s)
          del Usuario Final, documento(s) de identidad proporcionado(s) por el
          Usuario Final, o ambos. Este proceso de autenticación biométrica
          protege tanto al Usuario Final como a la Entidad Verificadora contra
          el acceso no autorizado a cuentas y el fraude de identidad. Cuando
          usted accede a "Powered by Trust Stamp" para verificar su identidad
          ante una Entidad Verificadora, usted es un "Usuario Final".
        </p>

        <p>
          <b>Cómo se utilizan los datos y la información biométricos?</b>
        </p>
        <p>
          La comparación de medidas biométricas sirve como mecanismo tanto para
          verificar la identidad de un Usuario Final como para confirmar que el
          documento o documentos de identidad presentados por un Usuario Final
          pertenecen a dicho Usuario Final. Para ello, recopilamos y procesamos
          sus medidas biométricas en bruto. Procesamos sus medidas biométricas
          en bruto, ya sean de una imagen en vivo o de un documento, a través de
          un proceso patentado para convertir las medidas en bruto en un Token
          de Identidad Transformado Irreversiblemente (IT2)™. El IT2 es un
          derivado único y tokenizado de sus medidas biométricas en bruto, pero
          no puede ser sometido a ingeniería inversa para recrear sus medidas en
          bruto. Tal y como se utiliza en estos Términos, "Datos Biométricos"
          incluye sus medidas biométricas en bruto, pero no incluye el IT2.
          Aunque el proceso utilizado para crear el IT2 es irreversible, el IT2
          conserva propiedades que pueden utilizarse para verificar su identidad
          sobre una base probabilística. En otras palabras, podemos comparar un
          IT2 generado actualmente con otro generado a partir de sus Datos
          Biométricos en el pasado o en el futuro para verificar su identidad.
          Utilizamos este proceso propio de comparación probabilística para
          verificar su identidad para una Entidad Verificadora.
        </p>

        <p>
          <u>
            <b>
              La letra pequeña (<i>muy importante; no la ignore ahora)</i>)
            </b>
          </u>
        </p>
        <p>
          <b>
            Tenga en cuenta que estas Condiciones incluyen, entre otras cosas,
            una disposición de arbitraje vinculante que le obliga a someterse a
            un arbitraje vinculante y definitivo de forma individual para
            resolver disputas, en lugar de un juicio con jurado o una demanda
            colectiva.
          </b>
        </p>
        <ol>
          <li>
            <p>
              <u>Aceptación; Política de privacidad.</u> Debe tener dieciocho
              (18) años o más (o la mayoría de edad legal en su jurisdicción de
              residencia, si es mayor) para utilizar el Sello Powered by Trust
              (en adelante, los "Servicios") para verificar su identidad o
              autenticar sus documentos de identidad. Al acceder y/o utilizar, o
              continuar accediendo o utilizando, los Servicios de cualquier
              modo, usted declara que (a) tiene al menos dieciocho (18) años de
              edad o la edad mínima requerida para celebrar un contrato legal
              vinculante en su jurisdicción de residencia, y que tiene el
              derecho, la autoridad y la capacidad para celebrar las presentes
              Condiciones; (b) está de acuerdo y acepta las presentes
              Condiciones, que también incorporan por referencia la Política de
              privacidad de Trust Stamp, que se encuentra aquí
              https://truststamp.ai/PrivacyPolicy.html (la "Política de
              privacidad"), cada una de las cuales podrá actualizarse
              ocasionalmente sin previo aviso, tal y como se establece en cada
              documento; (c) si se han producido cambios sustanciales en estos
              términos desde la última vez que accedió o utilizó los Servicios,
              reconoce y acepta que su acceso o uso continuado de los Servicios
              constituye su aceptación de los Términos modificados; y (d) da su
              consentimiento para recibir comunicaciones electrónicas de nuestra
              parte y acepta que dichas comunicaciones, notificaciones y/o
              publicaciones electrónicas satisfacen cualquier requisito legal
              que exija que dichas comunicaciones se realicen por escrito.
            </p>
          </li>

          <li>
            <p>
              <u>Acceso a los Servicios.</u>
              Sujeto al cumplimiento de estas Condiciones, le concedemos una
              licencia limitada, no exclusiva, no sublicenciable, revocable e
              intransferible para acceder y utilizar los Servicios. Es una
              condición para el uso de los Servicios que la información que
              proporcione sea correcta, actual y completa. El uso de los
              Servicios se realiza bajo su propia responsabilidad y usted
              reconoce que el proceso utilizado es probabilístico y no puede
              proporcionar una verificación absoluta de su identidad. Trust
              Stamp se reserva todos los derechos no concedidos expresamente en
              el presente documento. Usted es responsable de realizar todos los
              trámites necesarios para acceder a los Servicios. Los Servicios
              incluirán, sin limitación, cualesquiera servicios, aplicaciones o
              extensiones ofrecidos por nosotros que le proporcionemos
              directamente o que usted descargue o reciba de una Entidad
              Verificadora o de tiendas de aplicaciones de terceros, con
              sujeción a las condiciones de dichas tiendas o de sus proveedores.
              Para utilizar los Servicios, usted es responsable de disponer de
              hardware, software y acceso a Internet compatibles. No ofrecemos
              ninguna declaración ni garantía con respecto a los dispositivos
              que utilice para acceder a los Servicios o utilizarlos, incluida
              cualquier declaración con respecto a la compatibilidad de
              cualquier dispositivo con los Servicios.
            </p>
          </li>

          <li>
            <b>
              <p>
                <u>
                  Consentimiento para la recogida, tratamiento y uso de datos
                  biométricos.
                </u>
                Cuando utilice los Servicios, le pediremos que nos proporcione
                una o varias imágenes suyas (es decir, un "selfie") (su "Imagen
                de verificación"), documentos de identidad oficiales con
                fotografía (su "Documento de identificación"), o ambos. Al
                utilizar los Servicios, usted da su consentimiento para que
                recopilemos, procesemos, almacenemos y utilicemos sus datos
                biométricos derivados de su Imagen de Verificación, su(s)
                Documento(s) de Identificación y la(s) imagen(es) fotográfica(s)
                suya(s) conocida(s) o previamente verificada(s) que nos haya(n)
                proporcionado la Entidad Verificadora (su "Imagen de
                Identificación"). Utilizaremos su IT2 derivada de su Imagen de
                Verificación, Documento(s) de Identificación e Imagen de
                Identificación con el fin de verificar y autenticar su identidad
                como la misma persona representada en la Imagen de Verificación
                y en el Documento(s) de Identificación o en la Imagen de
                Verificación y en la Imagen de Identificación, dependiendo de la
                forma en que la Entidad Verificadora haya solicitado que
                verifiquemos su identidad. Usted no está obligado a dar su
                consentimiento, y nosotros no recogeremos, procesaremos,
                almacenaremos o utilizaremos sus Datos Biométricos o IT2 sin su
                consentimiento. Sin embargo, si decide no dar su consentimiento,
                no podrá verificar y autenticar su identidad ante la Entidad
                Verificadora utilizando los Servicios, lo que puede afectar a
                sus interacciones posteriores con la Entidad Verificadora.
                Deberá interactuar directamente con la Entidad Verificadora para
                resolver tales problemas.
                <br />
                <br />
                Salvo que se disponga lo contrario en las presentes Condiciones,
                no revelaremos ni divulgaremos sus Datos Biométricos o IT2 a
                nadie que no sea la Entidad Verificadora y nuestras subsidiarias
                y filiales sin obtener previamente su consentimiento por escrito
                para dicha revelación o divulgación, a menos que dicha
                revelación sea:
                <br />
                <ol>
                  <li>
                    <p>
                      exigida por leyes federales, estatales o provinciales u
                      ordenanzas municipales;
                    </p>
                  </li>
                  <li>
                    <p>
                      para denunciar el uso fraudulento o el intento de uso
                      fraudulento de los Servicios a las autoridades legales
                      competentes o
                    </p>
                  </li>
                  <li>
                    <p>
                      requerida en virtud de una orden escrita válida, demanda,
                      orden, citación, orden o mandato judicial emitido por un
                      tribunal de jurisdicción competente u otro organismo
                      gubernamental legalmente facultado para emitir dicho
                      proceso legal.
                    </p>
                  </li>
                </ol>
                Encontrará una copia de estas Condiciones en:{ ' ' }
                <a
                  href="https://truststamp.ai/TermsAndConditions.html?lang=es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://truststamp.ai/TermsAndConditions.html?lang=es
                </a>
              </p>
            </b>
          </li>

          <li>
            <p>
              <u>Divulgación de datos a la entidad verificadora.</u>
              Es posible que una entidad verificadora desee conservar su imagen
              de verificación, la imagen de su documento de identidad, los datos
              extraídos y su IT2 (en conjunto, "sus datos") para utilizarlos en
              la verificación de su identidad en el futuro. Usted nos autoriza a
              revelar su IT2 a una entidad verificadora con ese fin. Usted
              autoriza a dicha Entidad Verificadora a compartir sus Datos con
              nosotros en el futuro y a que utilicemos sus Datos para: (i)
              verificar su identidad y (ii) detectar actividades ilegales o
              fraudulentas.. Usted entiende y acepta que, una vez transferidos a
              una Entidad Verificadora, la retención, almacenamiento y uso de
              Sus Datos estarán sujetos a cualesquiera términos, condiciones y
              acuerdos entre usted y dicha Entidad Verificadora. Usted acepta
              que nosotros no somos responsables de los actos de dicha Entidad
              Verificadora con respecto a Sus Datos tal y como han sido
              revelados a la Entidad Verificadora.
            </p>
          </li>

          <li>
            <p>
              <u>
                Consentimiento para procesar y compartir la imagen de
                verificación y los datos del documento de identificación.
              </u>
              Al proporcionar su Imagen de Verificación, usted da expresamente
              su consentimiento para utilizar, procesar y compartir su Imagen de
              Verificación a la Entidad Verificadora con fines de verificación
              de identidad. Usted garantiza y declara que la Imagen cargada es
              suya y no de terceros. Confirma que dispone de todos los derechos
              y permisos necesarios para enviar esta Imagen con los fines
              indicados. Al proporcionar una imagen de su(s) Documento(s) de
              Identificación, usted declara que es el propietario y usuario
              legal de dicho(s) Documento(s) de Identificación y da su
              consentimiento para que utilicemos el reconocimiento óptico de
              caracteres (OCR), el escaneado digital y por radiofrecuencia y
              otras tecnologías para leer cualquier dato contenido en su
              Documento de Identificación, que pueden incluir, entre otros, su
              nombre, dirección, edad, fecha de nacimiento, lugar de nacimiento,
              nacionalidad, color de ojos, color de pelo, altura, peso, raza,
              origen étnico, número de documento de identidad, fecha de
              expedición o lugar de expedición (la "Información de
              identificación"). Al enviar una imagen de su documento de
              identidad, también reconoce que comprende y acepta que (1) podemos
              compartir su información de identidad, incluida la imagen de su
              documento de identidad, con la entidad verificadora, (2) el uso y
              la disposición posteriores de su información de identidad que
              transferimos a la entidad verificadora se regirán exclusivamente
              por los términos, condiciones y acuerdos entre usted y dicha
              entidad verificadora, (3) en la medida en que sea legal, y sin
              identificarle a usted como sujeto de dichos datos, podremos
              utilizar Sus Datos con fines de mejora, desarrollo y formación de
              nuestros sistemas y servicios. Esto incluye, a título meramente
              enunciativo y no limitativo, el uso de los datos en modelos de
              aprendizaje automático, análisis de datos y otros procesos de
              investigación y desarrollo y (4) con sujeción a las limitaciones
              adicionales de responsabilidad que se indican a continuación,
              usted, en su propio nombre y en el de sus herederos y
              causahabientes, se compromete a indemnizar a liberar y eximir a
              Trust Stamp de cualquier reclamación relacionada con el uso y
              disposición ulteriores de sus datos de identidad, incluidas, entre
              otras, las reclamaciones basadas en (a) el uso, divulgación o
              disposición indebidos de sus datos de identidad, (b) el fraude y
              robo de identidad, (c) la invasión de la privacidad, o (d) la
              imposición negligente o intencionada de angustia emocional.{ ' ' }
              <b>
                Si no está de acuerdo con estas limitaciones, NO debe enviar una
                imagen de su Documento de Identidad.
              </b>
            </p>
          </li>

          <li>
            <p>
              <u>Conservación de datos.</u>
              Sus Datos podrán ser conservados de conformidad con nuestras
              obligaciones para con una Entidad Verificadora. Las Entidades
              Verificadoras establecen un período de conservación de todos o
              parte de sus Datos. En ausencia de una orden judicial, citación,
              orden de detención, mandato judicial u otro requisito legal que
              nos obligue a hacerlo, en ningún caso conservaremos sus Datos
              biométricos, IT2 e Información de identificación durante más de
              treinta y seis (36) meses. Mientras tanto, puede solicitar que
              eliminemos Sus Datos. Para solicitar la eliminación de Sus Datos,
              envíe una solicitud por correo electrónico a{ ' ' }
              <a href="mailto:datadeletion@truststamp.ai">
                datadeletion@truststamp.ai{ ' ' }
              </a>{ ' ' }
              o por correo postal a:
              <br />
              <br />
              Data Deletion: Sello Fiduciario
              <br />
              1st and 2nd Floors, 3017 Bolling Way NE
              <br />
              Atlanta, GA, 30305
              <br />
              <br />
              Una solicitud para que eliminemos sus Datos Biométricos o IT2 no
              afectará a una verificación completada. La eliminación de sus
              datos biométricos o IT2 puede tardar hasta diez días laborables
              desde la recepción de la solicitud.
            </p>
          </li>

          <li>
            <p>
              <u>Datos del dispositivo y de la sesión.</u>
              También podemos recoger datos sobre el dispositivo desde el que se
              nos transmitió el Documento de Identificación ("Datos del
              Dispositivo") y la sesión de conexión a Internet durante la cual
              se nos transmitió su Documento de Identificación, Imagen de
              Identificación o Imagen de Verificación, incluida la dirección del
              Protocolo de Internet (IP) ("Datos de la Sesión"). Usted da su
              consentimiento para que capturemos y conservemos los Datos del
              Dispositivo y los Datos de la Sesión, y para que compartamos dicha
              información con la Entidad Verificadora.
            </p>
          </li>

          <li>
            <p>
              <u>Contenido.</u>
              Usted acepta que, de conformidad con las presentes Condiciones, y
              en virtud de sus garantías expresas en relación con los datos que
              envíe o reciba a través del uso de los Servicios, Trust Stamp no
              tiene ni ejerce ningún control editorial o subjetivo de otro tipo
              sobre el contenido de los datos, comunicaciones o mensajes que
              pueda enviar la Entidad verificadora. Trust Stamp no ejerce
              control alguno sobre la información retransmitida por la Entidad
              verificadora, y no se hace responsable de la exactitud, corrección
              o legalidad de dicha información. Usted es el único responsable de
              verificar la exactitud, legalidad e idoneidad de cualquier
              información y servicios que obtenga de una Entidad verificadora o
              de terceros a través o mediante el uso de los Servicios.
            </p>
          </li>

          <li>
            <p>
              <u>Almacenamiento de datos. </u>
              Utilizaremos un estándar razonable de cuidado para almacenar,
              transmitir y proteger de la divulgación cualquier dato
              electrónico, incluidos los datos biométricos, recopilados de
              usted. Dicho almacenamiento, transmisión y protección frente a la
              divulgación se llevará a cabo de un modo igual o más protector que
              el modo en que almacenamos, transmitimos y protegemos frente a la
              divulgación otra información confidencial y delicada.
            </p>
          </li>
          <li>
            <p>
              <u>No hay garantía de servicio.</u>
              Nuestro objetivo es que los Servicios estén siempre disponibles.
              Sin embargo, puede haber ocasiones en las que necesitemos
              modificar o deshabilitar los Servicios de forma temporal o
              permanente. Nos reservamos el derecho a interrumpir, modificar,
              suspender o desactivar, total o parcialmente, el acceso a los
              Servicios o imponer límites de uso o acceso a los Servicios, con o
              sin previo aviso, y en todos los casos sin responsabilidad alguna.
              En ningún caso seremos responsables de la interrupción,
              modificación, suspensión o desactivación del acceso a los
              Servicios en cualquier momento o por cualquier duración.
            </p>
          </li>
          <li>
            <p>
              <u>Uso lícito únicamente.</u>
              Sólo podrá utilizar los Servicios para fines lícitos y de
              conformidad con las presentes Condiciones. No tenemos ninguna
              obligación de hacer cumplir las Condiciones en su nombre frente a
              otro Usuario final, persona o entidad. Usted se compromete a no
              ayudar, animar o permitir a otros el uso de los Servicios:
            </p>
            <ol type="a">
              <li>
                <p>
                  para fines distintos de los expresamente permitidos en las
                  presentes Condiciones;
                </p>
              </li>
              <li>
                <p>
                  de forma abusiva, difamatoria, injuriosa, ofensiva,
                  amenazadora, invasiva del derecho a la intimidad de otra
                  persona o de la que quepa esperar razonablemente que cause
                  daño a cualquier persona o entidad;
                </p>
              </li>
              <li>
                <p>
                  violar cualquier ley o normativa nacional, regional, federal,
                  estatal, local o internacional aplicable, o fomentar cualquier
                  actividad ilegal;
                </p>
              </li>
              <li>
                <p>
                  crear, enviar, recibir a sabiendas, mostrar, transmitir o
                  utilizar cualquier material que
                </p>
                <ol type="i">
                  <li>
                    <p>
                      infrinja cualquier patente, marca comercial, secreto
                      comercial, derecho de autor u otro(s) derecho(s) de
                      propiedad intelectual de cualquier otra persona;
                    </p>
                  </li>
                  <li>
                    <p>
                      contenga imágenes de pornografía infantil o material de
                      abuso sexual infantil; o ' ' }
                    </p>
                  </li>
                  <li>
                    <p>
                      viole de cualquier otro modo, o contribuya a la violación
                      de, los derechos legales (incluidos los derechos de
                      publicidad y privacidad) de terceros o contenga cualquier
                      material que pueda dar lugar a cualquier responsabilidad
                      civil o penal en virtud de las leyes o reglamentos
                      aplicables, o que de cualquier otro modo pueda entrar en
                      conflicto con estas Condiciones o nuestra Política de
                      privacidad; o que pueda engañar o confundir a cualquier
                      persona;
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  infringir las presentes Condiciones o cualesquiera otras
                  normas o políticas publicadas por nosotros en nuestro sitio
                  web o que se le hayan facilitado de otro modo;
                </p>
              </li>
              <li>
                <p>
                  transmitir virus informáticos, gusanos, defectos, troyanos u
                  otros programas maliciosos; u
                </p>
              </li>
              <li>
                <p>
                  obtener acceso no autorizado o violar la seguridad de
                  cualquier red informática.
                </p>
              </li>
            </ol>
            <p>
              Usted se compromete a no ayudar, alentar o permitir que otros lo
              hagan:
            </p>
            <ol type="a" start="8">
              <li>
                <p>
                  realizar ingeniería inversa, descompilar, ensamblar a la
                  inversa o realizar cualquier otra operación en o con los
                  Servicios que pudiera revelar o divulgar cualquier código
                  fuente, secreto comercial u otra información de propiedad;
                </p>
              </li>
              <li>
                <p>
                  modificar, adaptar, apropiarse, reproducir, distribuir,
                  traducir, crear obras derivadas o adaptaciones de, mostrar
                  públicamente, vender, comerciar o explotar de cualquier modo
                  los Servicios, salvo autorización expresa por nuestra parte;
                </p>
              </li>
              <li>
                <p>
                  eliminar o modificar cualquier aviso de copyright, marca
                  comercial u otros derechos de propiedad que aparezca en
                  cualquier parte de los Servicios o en cualquier material
                  impreso o copiado de los Servicios;
                </p>
              </li>
              <li>
                <p>
                  registrar, procesar, cosechar, recopilar o extraer información
                  sobre otros usuarios;
                </p>
              </li>
              <li>
                <p>
                  utilizar cualquier robot, script u otro proceso o medio
                  automático para acceder a los Servicios con cualquier fin;
                </p>
              </li>
              <li>
                <p>
                  eliminar, eludir, inutilizar, dañar o interferir de cualquier
                  otro modo con las funciones de seguridad u otras restricciones
                  de los Servicios;
                </p>
              </li>
              <li>
                <p>
                  intentar obtener acceso no autorizado, interferir, dañar o
                  interrumpir cualquier parte de los Servicios, el servidor en
                  el que se almacenan los Servicios o cualquier servidor,
                  ordenador o base de datos conectada a los Servicios; atacar
                  los Servicios mediante una denegación de servicio, denegación
                  de servicio distribuida (DDoS) u otra forma de ciberataque; o
                  intentar interferir de cualquier otro modo en el correcto
                  funcionamiento de los Servicios.
                </p>
              </li>
            </ol>
            <p>
              Las restricciones anteriores sólo se aplican en la medida
              permitida por la legislación aplicable. No obstante, usted se
              compromete a no actuar en contra de las mismas (incluso si sus
              acciones son por lo demás lícitas) sin notificarnos por escrito
              con treinta (30) días de antelación a{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>{ ' ' }
              su intención de hacerlo, junto con cualquier información que
              podamos requerir razonablemente para darnos la oportunidad de
              acomodar o abordar de otro modo su acción propuesta, lo cual
              quedará a nuestra entera y absoluta discreción.
            </p>
          </li>
          <li>
            <p>
              <u>Envíos fraudulentos.</u>
              Cualquier envío o intento de envío de una Imagen de Verificación,
              Imagen de Identificación o Documento de Identificación que sea
              fraudulento, o enviado en un intento de cometer fraude,
              incluyendo, sin limitación, acciones que violen el Párrafo 11
              anterior, se considerará un Envío Fraudulento. Sin perjuicio de lo
              anterior, en caso de que realice un envío fraudulento, usted
              acepta que podamos retener, procesar, almacenar y utilizar todos
              los datos enviados, incluidos los datos biométricos y la
              información de identificación, durante un período de hasta diez
              (10) años con fines de (i) investigación y persecución de
              actividades fraudulentas y (ii) formación y mejora de nuestros
              sistemas en la detección y prevención del fraude. Si lo solicita
              por escrito a{ ' ' }
              <a href="mailto:support@truststamp.net">support@truststamp.net</a>
              , puede pedir que sus datos no se utilicen con este fin. No
              obstante, para atender su solicitud, podemos exigirle que nos
              proporcione documentación que acredite su identidad como
              propietario legítimo de los datos asociados a un envío
              fraudulento.
            </p>
          </li>
          <li>
            <p>
              <u>Tasas de citación.</u>
              Si se nos exige que proporcionemos información en respuesta a una
              orden judicial, citación, orden de arresto, mandato judicial o
              investigación legal o gubernamental similar relacionada con su uso
              de los Servicios, podremos cobrarle nuestros costes. Estos
              honorarios pueden incluir el tiempo razonable empleado por el
              abogado y los empleados en recuperar los registros, preparar los
              documentos y participar en una declaración o vista judicial.
            </p>
          </li>
          <li>
            <p>
              <u>Propiedad intelectual.</u>
              Usted conservará todos los derechos, títulos e intereses sobre el
              material, contenido, datos e información (incluida su información
              personal) que nos envíe al utilizar los Servicios (colectivamente,
              su "Contenido"). Con sujeción a las presentes Condiciones, usted
              nos autoriza a utilizar o divulgar su Contenido únicamente en la
              medida en que sea necesario para prestarle los Servicios o en la
              medida en que lo permitan las presentes Condiciones. Usted declara
              y garantiza que:
            </p>
            <ol type="a">
              <li>
                <p>
                  posee o ha obtenido de otro modo todos los derechos,
                  liberaciones y permisos necesarios para enviar todo su
                  Contenido a los Servicios y para otorgarnos los derechos que
                  se nos conceden en estos Términos;
                </p>
              </li>
              <li>
                <p>
                  no enviará ni intentará autorizar la recopilación, el
                  procesamiento, el almacenamiento o el uso de datos biométricos
                  de ninguna imagen o identificación fotográfica en la que usted
                  no sea la persona representada; y
                </p>
              </li>
              <li>
                <p>
                  su Contenido y su envío y uso, tal y como autoriza en estas
                  Condiciones, no infringirán (i) ninguna ley o normativa
                  aplicable ni (ii) ningún derecho de propiedad intelectual,
                  privacidad, publicidad u otros derechos de terceros.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <b>
                <u>Terminación de los Servicios.</u>
                Trust Stamp podrá cancelar su acceso a la totalidad o parte de
                los Servicios en cualquier momento, con o sin causa, con o sin
                previo aviso, con efecto inmediato. Si desea cancelar su
                registro y su cuenta, puede hacerlo en cualquier momento
                poniéndose en contacto con nosotros en{ ' ' }
                <a href="mailto:support@truststamp.net">
                  support@truststamp.net
                </a>
                . En el momento de la rescisión, cesarán todos los derechos y
                obligaciones de las partes, y usted deberá dejar de utilizar los
                Servicios inmediatamente, salvo que (a) todas las obligaciones
                devengadas con anterioridad a la fecha efectiva de rescisión,
                (b) todos los recursos por incumplimiento de las presentes
                Condiciones, y (c) las disposiciones de las Secciones 4, 5, 12,
                13, 16, 17, 18, 19, 20 y 21 subsistirán.
              </b>
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>EXCLUSIÓN DE GARANTÍAS.</b>
              </u>
              TRUST STAMP RENUNCIA POR LA PRESENTE A TODAS LAS GARANTÍAS DE
              CUALQUIER TIPO, YA SEAN EXPRESAS, IMPLÍCITAS, LEGALES O DE OTRO
              TIPO, INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS DE EXACTITUD,
              COMERCIABILIDAD, NO INFRACCIÓN, DISFRUTE TRANQUILO O IDONEIDAD
              PARA UN FIN DETERMINADO. EN LA MEDIDA MÁXIMA PERMITIDA POR LA
              LEGISLACIÓN APLICABLE, LOS SERVICIOS SE PRESTAN EXCLUSIVAMENTE
              "TAL CUAL" Y "SEGÚN DISPONIBILIDAD". TRUST STAMP NO GARANTIZA LA
              EXACTITUD, PUNTUALIDAD, INTEGRIDAD O UTILIDAD DE LOS SERVICIOS O
              DE CUALQUIER CONTENIDO. USTED ACEPTA UTILIZAR LOS SERVICIOS POR SU
              CUENTA Y RIESGO. TRUST STAMP NO GARANTIZA QUE NO SE PRODUZCAN
              INTERFERENCIAS EN SU DISFRUTE DE LOS SERVICIOS, QUE LAS FUNCIONES
              CONTENIDAS EN LOS SERVICIOS O LOS SERVICIOS PRESTADOS POR O A
              TRAVÉS DE LOS SERVICIOS SATISFAGAN SUS NECESIDADES, QUE EL
              FUNCIONAMIENTO DE LOS SERVICIOS SEA ININTERRUMPIDO O ESTÉ LIBRE DE
              ERRORES, O QUE SE CORRIJAN LOS DEFECTOS DE LOS SERVICIOS. SIN
              PERJUICIO DE LO ANTERIOR, TRUST STAMP RECHAZA CUALQUIER GARANTÍA
              DERIVADA DEL CURSO DE LOS NEGOCIOS, DEL CURSO DE LA EJECUCIÓN O DE
              LOS USOS COMERCIALES. NINGUNA INFORMACIÓN ORAL O ESCRITA
              PROPORCIONADA POR TRUST STAMP O SU(S) REPRESENTANTE(S) SE
              CONSIDERARÁ CONSTITUTIVA DE GARANTÍA. SI NO ESTÁ SATISFECHO CON
              LOS SERVICIOS O CON CUALQUIERA DE ESTAS CONDICIONES, SU ÚNICO
              RECURSO ES DEJAR DE UTILIZAR LOS SERVICIOS. ALGUNAS JURISDICCIONES
              NO PERMITEN LA EXCLUSIÓN DE GARANTÍAS IMPLÍCITAS NI LA LIMITACIÓN
              DE LOS DERECHOS LEGALES APLICABLES DE UN CONSUMIDOR. SI USTED
              RESIDE EN UNA DE ESTAS JURISDICCIONES, ES POSIBLE QUE NO SE LE
              APLIQUEN LAS EXCLUSIONES Y LIMITACIONES ANTERIORES.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>LIMITACIÓN DE RESPONSABILIDAD.</b>
              </u>
              USTED RECONOCE QUE SU ACCESO Y USO DE LOS SERVICIOS Y CUALQUIER
              CONFIANZA EN CUALQUIERA DE ELLOS SON BAJO SU PROPIO RIESGO. EN LA
              MEDIDA MÁXIMA PERMITIDA POR LA LEY, EN NINGÚN CASO TRUST STAMP O
              CUALQUIERA DE SUS PROPIETARIOS, GERENTES, FUNCIONARIOS,
              DIRECTORES, MIEMBROS, ACCIONISTAS, FILIALES, SUBSIDIARIAS,
              EMPLEADOS, CONTRATISTAS, AGENTES O REPRESENTANTES SERÁN
              RESPONSABLES DE NINGÚN DAÑO CONSECUENTE, EJEMPLAR, INDIRECTO,
              INCIDENTAL O ESPECIAL, INCLUYENDO, SIN LIMITACIÓN, DAÑOS POR LUCRO
              CESANTE, EXPOSICIÓN O PÉRDIDA DE DATOS, INTERRUPCIÓN DE NEGOCIO
              Y/O CUALQUIER OTRO DAÑO O PÉRDIDA COMERCIAL, QUE SURJA DE O EN
              CONEXIÓN CON LOS SERVICIOS, TANTO SI TRUST STAMP HA SIDO ADVERTIDO
              O NO DE LA POSIBILIDAD DE TALES DAÑOS, EN CUALQUIER TEORÍA DE
              RESPONSABILIDAD (INCLUYENDO CONTRATO, NEGLIGENCIA EXTRACONTRACTUAL
              O DE OTRO TIPO) QUE SURJA DE, EN CONEXIÓN CON O COMO RESULTADO DE
              LOS SERVICIOS. USTED RECONOCE ESPECÍFICAMENTE QUE EL TIEMPO DE
              INACTIVIDAD DEL ORDENADOR Y LOS VIRUS SON RIESGOS INHERENTES AL
              USO DE INTERNET Y DE CUALQUIER PRODUCTO DE SOFTWARE, Y ACEPTA
              ASUMIR LA RESPONSABILIDAD POR CUALQUIER DAÑO O PERJUICIO DE
              CUALQUIER TIPO O CARÁCTER QUE RESULTE DE ESTOS POSIBLES DAÑOS.
              USTED TAMBIÉN RECONOCE ESPECÍFICAMENTE QUE PUEDE ESTAR REVELANDO
              INFORMACIÓN SENSIBLE, PRIVADA Y CONFIDENCIAL, INCLUIDOS DATOS
              BIOMÉTRICOS E INFORMACIÓN BIOMÉTRICA, SOBRE USTED MISMO A TRAVÉS
              DE SU USO DE LOS SERVICIOS Y ACEPTA ASUMIR LA RESPONSABILIDAD DE
              CUALQUIER DAÑO O PERJUICIO DE CUALQUIER TIPO O CARÁCTER QUE
              RESULTE DE SU REVELACIÓN DE DICHO CONTENIDO. EN NINGÚN CASO
              NUESTRA RESPONSABILIDAD TOTAL FRENTE A USTED POR TODOS Y CADA UNO
              DE LOS DAÑOS Y PERJUICIOS (SALVO QUE ASÍ LO EXIJA LA LEGISLACIÓN
              APLICABLE) SUPERARÁ EL IMPORTE MAYOR DE (A) LA CANTIDAD PAGADA POR
              USTED POR LOS SERVICIOS EN LOS SEIS (6) MESES ANTERIORES A LA
              RECLAMACIÓN APLICABLE, SI LA HUBIERA, O (B) CIEN DÓLARES (100,00
              $). TANTO USTED COMO NOSOTROS TENEMOS LA INTENCIÓN DE QUE ESTA
              DISPOSICIÓN SEA INTERPRETADA POR UN TRIBUNAL COMO LA LIMITACIÓN DE
              RESPONSABILIDAD MÁS AMPLIA PERMITIDA POR LA LEGISLACIÓN APLICABLE.
              ALGUNAS JURISDICCIONES NO PERMITEN LA LIMITACIÓN O EXCLUSIÓN DE
              DAÑOS INCIDENTALES, CONSECUENTES O DE OTRO TIPO, POR LO QUE ES
              POSIBLE QUE ALGUNAS DE LAS LIMITACIONES ANTERIORES NO SE APLIQUEN
              EN SU CASO. ESTAS LIMITACIONES SE APLICARÁN INCLUSO EN EL CASO DE
              QUE LA SOLUCIÓN INDICADA ANTERIORMENTE NO CUMPLA SU FINALIDAD
              ESENCIAL.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>ARBITRAJE VINCULANTE OBLIGATORIO.</b>{ ' ' }
              </u>
              CUALQUIER DISPUTA, DIFERENCIA, CONTROVERSIA O RECLAMACIÓN QUE
              SURJA DE O ESTÉ RELACIONADA CON ESTAS CONDICIONES O CON SU USO DE
              LOS SERVICIOS SE RESOLVERÁ MEDIANTE ARBITRAJE DEFINITIVO Y
              VINCULANTE DE ACUERDO CON LAS NORMAS DE LOS SERVICIOS DE MEDIACIÓN
              Y ARBITRAJE DE HENNING EN ATLANTA, GEORGIA. EL ARBITRAJE SE
              CELEBRARÁ CON UN SOLO ÁRBITRO. EL ÁRBITRO PODRÁ CONCEDER LAS
              COSTAS Y/O LOS HONORARIOS DE LOS ABOGADOS A LA PARTE VENCEDORA.
              USTED ENTIENDE QUE EL ARBITRAJE ES DEFINITIVO Y VINCULANTE Y QUE
              RENUNCIA A SUS DERECHOS A OTROS PROCESOS DE RESOLUCIÓN (COMO UNA
              ACCIÓN JUDICIAL O UN PROCEDIMIENTO ADMINISTRATIVO).
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>RENUNCIA A ACCIONES COLECTIVAS Y JUICIOS CON JURADO.</b>
              </u>
              CON RESPECTO A SU USO DE LOS SERVICIOS, INDEPENDIENTEMENTE DE SI
              HA OBTENIDO O UTILIZADO LOS SERVICIOS CON FINES PERSONALES,
              COMERCIALES O DE OTRO TIPO, TODAS LAS RECLAMACIONES DEBEN
              PRESENTARSE A TÍTULO INDIVIDUAL Y NO COMO DEMANDANTE O MIEMBRO DE
              UNA CLASE EN CUALQUIER PRETENDIDA ACCIÓN DE CLASE, ACCIÓN
              COLECTIVA, ACCIÓN DE ABOGADO GENERAL PRIVADO U OTRO PROCEDIMIENTO
              REPRESENTATIVO. ESTA RENUNCIA TAMBIÉN SE APLICA AL ARBITRAJE
              COLECTIVO, Y UN ÁRBITRO NO PUEDE CONSOLIDAR LAS RECLAMACIONES DE
              MÁS DE UNA PERSONA EN NINGUNA ACCIÓN. USTED ACEPTA QUE, AL ACEPTAR
              ESTAS CONDICIONES, TANTO USTED COMO TRUST STAMP RENUNCIAN AL
              DERECHO A UN JUICIO CON JURADO O A PARTICIPAR EN UNA DEMANDA
              COLECTIVA, ACCIÓN DE CLASE, ACCIÓN DE ABOGADO GENERAL PRIVADO U
              OTRO PROCEDIMIENTO REPRESENTATIVO DE CUALQUIER TIPO.
            </p>
          </li>
          <li>
            <p>
              <u>
                <b>INDEMNIZACIÓN.</b>
              </u>
              USTED ACEPTA INDEMNIZAR Y MANTENER INDEMNE A TRUST STAMP, SUS
              FILIALES Y AFILIADOS Y SUS RESPECTIVOS PROPIETARIOS, GERENTES,
              FUNCIONARIOS, DIRECTORES, MIEMBROS, ACCIONISTAS, EMPLEADOS,
              CONTRATISTAS, AGENTES, LICENCIANTES Y PROVEEDORES DE Y CONTRA
              CUALQUIER RECLAMACIÓN, RESPONSABILIDAD, DAÑOS, SENTENCIAS,
              PREMIOS, PÉRDIDAS, COSTOS, GASTOS Y / U HONORARIOS (INCLUYENDO
              HONORARIOS RAZONABLES DE ABOGADOS) RESULTANTES DE (A) SU VIOLACIÓN
              DE ESTOS TÉRMINOS; (B) SU USO O ACCESO A LOS SERVICIOS; (C) SU
              VIOLACIÓN DE CUALQUIER DERECHO DE TERCEROS, INCLUYENDO, SIN
              LIMITACIÓN, CUALQUIER DERECHO DE PROPIEDAD INTELECTUAL O DERECHOS
              DE PUBLICIDAD; O (D) CUALQUIER RECLAMACIÓN DE QUE SU USO DE LOS
              SERVICIOS CAUSÓ DAÑOS A UN TERCERO..
            </p>
          </li>
          <li>
            <p>
              <u>Modificación de las Condiciones.</u>
              Nos reservamos el derecho a modificar estas Condiciones y a añadir
              condiciones nuevas o adicionales sobre su uso de los Servicios,
              por cualquier motivo, sin previo aviso y en cualquier momento.
              Dichas modificaciones y condiciones adicionales entrarán en vigor
              inmediatamente y se incorporarán a las presentes Condiciones. Su
              uso continuado de los Servicios se considerará una aceptación de
              las mismas. Le notificaremos los cambios sustanciales
              solicitándole que los acepte antes de utilizar los Servicios en el
              futuro. Las Condiciones actuales también estarán disponibles en
              nuestro sitio web.. Lo que constituya "cambios materiales" se
              determinará a nuestra entera discreción, de buena fe y utilizando
              el sentido común y un juicio razonable..
            </p>
          </li>
          <li>
            <p>
              <u>Condiciones generales.</u>
              Las presentes Condiciones constituyen el acuerdo único y completo
              entre usted y Trust Stamp en relación con los Servicios y
              sustituyen a todos los acuerdos, manifestaciones y garantías
              anteriores y contemporáneos, tanto escritos como orales, en
              relación con los Servicios. Ninguna renuncia por nuestra parte a
              las presentes Condiciones se considerará una renuncia ulterior o
              continuada a dicho término o condición o a cualquier otro término
              o condición, y el hecho de que no hagamos valer un derecho o
              disposición en virtud de las presentes Condiciones o nos demoremos
              en hacerlo no constituirá una renuncia a dicho derecho o
              disposición. Si un tribunal competente considera que alguna de las
              disposiciones de estas Condiciones es inválida, ilegal o
              inaplicable por cualquier motivo, dicha disposición se modificará
              para reflejar la intención de las partes o se eliminará en la
              menor medida posible, de forma que las restantes disposiciones de
              las Condiciones sigan siendo plenamente vigentes y efectivas.
              Usted no podrá ceder, transferir ni sublicenciar las Condiciones,
              ni ninguno de sus derechos u obligaciones, salvo con nuestro
              consentimiento previo por escrito, pero nosotros podremos cederlas
              o transferirlas sin restricciones. Cualquier intento de cesión por
              su parte infringirá estas Condiciones y será nulo. Los títulos de
              las secciones de las presentes Condiciones son meramente
              indicativos y carecen de efectos legales o contractuales. Por la
              presente, otorga a Trust Stamp el derecho a tomar las medidas
              razonablemente necesarias o apropiadas para hacer cumplir y/o
              verificar el cumplimiento de cualquier parte de estas Condiciones.
              Usted acepta que tenemos derecho, sin responsabilidad para con
              usted, a revelar cualquier dato y/o información a las autoridades
              policiales, funcionarios gubernamentales y/o terceros, según
              consideremos razonablemente necesario o apropiado para hacer
              cumplir y/o verificar el cumplimiento de cualquier parte de estas
              Condiciones (incluyendo, sin limitación, nuestro derecho a
              cooperar con cualquier proceso legal relacionado con su uso de los
              Servicios, y/o la reclamación de un tercero de que su uso de los
              Servicios es ilegal y/o infringe los derechos de dicho tercero).
            </p>
          </li>
          <li>
            <p>
              <u>Legislación aplicable y jurisdicción.</u>
              Las presentes Condiciones y la licencia concedida en virtud de las
              mismas se regirán por la legislación del Estado de Georgia, sin
              perjuicio de los principios sobre conflicto de leyes. Para
              cualquier reclamación a la que se considere inaplicable la
              disposición de arbitraje obligatorio contenida en el presente
              documento, usted acepta someterse a la jurisdicción personal de
              los tribunales federales y estatales ubicados en Atlanta, Georgia.
              Su uso de los Servicios también puede estar sujeto a otras leyes
              locales, estatales, federales o internacionales.
            </p>
            <b>
              <p>
                Al proporcionar voluntariamente una Imagen de Verificación o un
                Documento de Identificación, usted está reconociendo y aceptando
                estas Condiciones y está:
              </p>

              <ol type="a">
                <li>
                  <p>
                    declarando que ha leído, entendido y consentido estos
                    Términos en su totalidad, incluyendo todas las disposiciones
                    relacionadas con la recopilación, procesamiento,
                    almacenamiento y uso de sus Datos Biométricos;
                  </p>
                </li>
                <li>
                  <p>
                    declarando que usted es la persona representada en la Imagen
                    de Verificación; la Imagen de Verificación es una imagen
                    real e inalterada de usted capturada por la cámara del
                    dispositivo que se utiliza en el proceso (es decir, no, por
                    ejemplo, una foto de una foto o un vídeo de un vídeo); su
                    Documento de Identificación es un documento legalmente
                    producido y válidamente emitido por la jurisdicción
                    reflejada en el mismo en el que su imagen y otros datos
                    están inalterados;
                  </p>
                </li>
                <li>
                  <p>
                    nos designa como su agente con el único fin limitado de
                    estampar su firma electrónica en estas Condiciones en su
                    nombre y nos ordena que estampe y asocie su dirección IP y
                    la fecha y hora de su reconocimiento de estas Condiciones
                    como su firma electrónica legalmente vinculante que da fe de
                    su comprensión y reconocimiento y aceptación por escrito de
                    estas Condiciones;
                  </p>
                </li>
                <li>
                  <p>
                    aceptando que su uso continuado de los Servicios constituye
                    su consentimiento permanente a estas Condiciones.
                  </p>
                </li>
              </ol>
            </b>
          </li>
        </ol>
      </div>
    );
  }
}
