/* eslint-disable max-len */
import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'sf/helpers';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import { CONTENT } from './constant';

export default class Work extends BaseComponent {
  className = 'ts-Work';

  render() {
    return (
      <section className={ this.rootcn() }>
        <div className={ this.cn`__container ts-container` }>
          <div className={ this.cn`__content` }>
            <div className={ this.cn`__left-content` }>
              <h6>WHAT WE DO</h6>
              <h2>Establishing Trust in a Digitally Connected World</h2>
              <p>
                Trust Stamp delivers trusted systems at the heart of how
                governments, organizations, and individuals transact in the
                connected world and beyond.
              </p>
            </div>
            <div className={ this.cn`__right-content` }>
              <img
                src={ asset`ai/technology-new-1.png` }
                alt="Trust Stamp - What we do"
              />
            </div>
          </div>

          { /* what we do list */ }
          { /* <div className={ this.cn`__features` }>
            { CONTENT.map(({ id, title, content, slug }) => (
              <div className={ this.cn`__column` } key={ id }>
                <img src={ asset`ai/${slug}.svg` } alt={ title } />
                <h3>{ title }</h3>
                <p>{ content }</p>
              </div>
            )) }

          </div>

          <Link to={ ROUTES.IDENTITY_TOOLS } className="ts-button--brand">View our products</Link> */ }
        </div>
      </section>
    );
  }
}
