/* global CAPTCHA_SITE_KEY */

import React from 'react';
import PropTypes from 'prop-types';
import at from 'lodash/at';

import Button from 'components/Button';
import contact from 'models/contact';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import ValidationInput from 'sf/components/ValidationInput';

import captchaModel from 'sf/components/Captcha/model';

const REASONS = [
  'Biometric MFA',
  'Biometric Identity Proofing',
  'Biometric Duplicate Detection',
  'Trusted Check-In',
  'PII Tokenization',
  'Partnership with Trust Stamp',
  'Investor Questions',
  'Press',
  'Other'
].map((label) => ({ label, value: label }));

class CalculatorForm extends BasePage {
  className = 'ts-CorporateContactUsAi';

  static propTypes = {
    onDone: PropTypes.func.isRequired,
    source: PropTypes.string,
    // Contact form is sent to [sales@truststamp.net, support@truststamp.net,
    // social@truststamp.net, privacy@truststamp.net]
    recipient: PropTypes.oneOf(['sales', 'support', 'social', 'privacy'])
  };

  static defaultProps = {
    source: 'Truststamp.ai',
    recipient: 'sales'
  };

  state = {};

  componentDidMount() {
    contact.set({
      email: user.get('email')
    });

    this.syncStateWithModel(contact, [
      'email',
      'country',
      'phone',
      'reason',
      'message'
    ]);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    Button.setProcessing(this.submitButton);
    const hideProcessing = () => {
      Button.setProcessing(this.submitButton, false);
    };

    this.formValidation(contact, 'form.')
      .then(() => {
        const done = () => {
          this.props.onDone();
          contact.clear();
        };
        const fail = (err = {}) => {
          if (Object.keys(err.validation || {}).length) {
            this.formValidation(Promise.reject(err.validation));
          }
          console.log(err); // eslint-disable-line no-console
          this.publish('showFloatingText', {
            text: 'Error sending message. Please try again.',
            isValid: false
          });
        };

        contact
          .sendScheduleDemoMessage(this.props.recipient, {
            includeDebugInfo: false,
            captchaResponse: this.state.captchaResponse,
            source: this.props.source
          })
          .then(done, fail);
      })
      .finally(hideProcessing);
  };

  resetCaptcha() {
    // reset captcha when validation fails to fix TRST-757
    this.handleCaptchaVerification(null);
    captchaModel.resetCaptcha();
  }

  handleCaptchaVerification = (response) => {
    this.setState({
      captchaResponse: response
    });
  };

  render() {
    const isFormValid = at(this.state, [
      'email',
      'reason'
      // 'captchaResponse',
    ]).every(Boolean);

    return (
      <div className={ this.rootcn() }>
        <form
          onSubmit={ this.handleSubmit }
          className={ this.cn`__form` }
          name="Trust Stamp Contact Us"
        >
          <div className={ this.cn`__form-group` }>
            <p>
              2. Approximately how many customers do you verify each month?
            </p>
            <label htmlFor="">No need for an exact, just enter any number to estimate.</label>
            <ValidationInput
              className={ this.cn`__input` }
              type="text"
              placeholder="-"
              ref="form.month_verification"
              stateLink={ [this, 'month_verification'] }
            />
          </div>

          <div className={ this.cn`__form-group` }>
            <p>
              3. What does your current identity verification partner charge
              per verification?
            </p>
            { /* <label htmlFor="">Company Email Address *</label> */ }
            <ValidationInput
              className={ this.cn`__input` }
              type="text"
              placeholder="-"
              ref="form.current_identity"
              stateLink={ [this, 'current_identity'] }
            />
          </div>

          <div className={ this.cn`__form-group` }>
            <p>
              4. What is the average year-one revenue generated from each new
              customer you onboard?
            </p>
            { /* <label htmlFor="">Company Email Address *</label> */ }
            <ValidationInput
              className={ this.cn`__input` }
              type="text"
              placeholder="-"
              ref="form.revenue_generated"
              stateLink={ [this, 'revenue_generated'] }
            />
          </div>

          <div className={ this.cn`__form-group` }>
            <p>
              5. What percentage of customers drop off during your current
              verification process?
            </p>
            { /* <label htmlFor="">Company Email Address *</label> */ }
            <ValidationInput
              className={ this.cn`__input` }
              type="range"
              placeholder="%"
              ref="form.customers_drop "
              stateLink={ [this, 'customers_drop'] }
              value="0"
            />
            <strong>{ this.state.customers_drop || '0' }%</strong>
          </div>

          <div className={ this.cn`__form-group` }>
            <p>6. What percentage of new applicants are fraudulent?</p>
            { /* <label htmlFor="">Company Email Address *</label> */ }
            <ValidationInput
              className={ this.cn`__input` }
              type="range"
              placeholder="%"
              ref="form.fraudulent"
              stateLink={ [this, 'fraudulent'] }
              value="0"
            />
            <strong>{ this.state.fraudulent || '0' }%</strong>
          </div>

          { /* message? */ }
          <hr className={ this.cn`__divider` } />

          <p className={ this.cn`__agree` }>
            This tool provides an estimate of potential cost savings with a
            Trust Stamp identity verification solution, based on industry
            averages. For a detailed ROI assessment, connect with a Trust Stamp
            expert at <a href="sales@truststamp.net">
              sales@truststamp.net
                      </a>{ ' ' }
          </p>
        </form>
      </div>
    );
  }
}

export default CalculatorForm;
